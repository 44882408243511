import React, { createContext, useContext, useState, ReactNode } from 'react';

interface GatedLinkModalContextType {
  activeModal: string | null;
  setActiveModal: (modalId: string | null) => void;
}

const GatedLinkModalContext = createContext<GatedLinkModalContextType>({
  activeModal: null,
  setActiveModal: () => {}
});

export const useGatedLinkModalContext = () => useContext(GatedLinkModalContext);

interface GatedLinkModalProviderProps {
  children: ReactNode;
}

export const GatedLinkModalProvider: React.FC<GatedLinkModalProviderProps> = ({ children }) => {
  const [activeModal, setActiveModal] = useState<string | null>(null);

  return (
    <GatedLinkModalContext.Provider value={{ activeModal, setActiveModal }}>
      {children}
    </GatedLinkModalContext.Provider>
  );
};
