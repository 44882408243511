import {
  signIn as cognitoSignIn,
  signOut as cognitoSignOut,
  signUp as cognitoSignUp
} from "aws-amplify/auth";
import { trackMetric } from "../";
import { generatePassword } from "../../hooks/useAuth/useAuth";

const eventId = process.env.GATSBY_EVENT_ID || ""; // If undefined there will be a type-check error
const allowedErrors = ["UsernameExistsException", "InvalidParameterException"];
interface EventUser {
  email: string;
  given_name: string;
  family_name: string;
}

export const registerV2Alpha = async (
  { email, given_name, family_name }: EventUser,
  params: Record<string, string>
) => {
  const config = {
    username: email.toLowerCase(),
    password: generatePassword(),
    attributes: {
      email,
      given_name,
      family_name,
    },
    clientMetadata: {
      eventId,
      ...params,
    },
  };
  const payload = {
    email,
    eventId,
    ...params,
  }
  
  try {
    const cognitoUser = await cognitoSignUp(config);
    await trackMetric({
      metricType: "registration",
      payload,
    });
    return cognitoUser;
  } catch (error: any) {
    console.log("error:", error);
    if (!allowedErrors.includes(error?.name)) {
      throw Error(error?.message);
    } else {
      await trackMetric({
        metricType: "registration",
        payload,
      });
    }
  }
};

export const register = async (
  { formData, context }: { formData: any; context: any },
  onFailure: Function
) => {
  if (!formData) {
    throw Error(
      `Expected SignUpFormData type but got underfined while signing up.`
    );
  }

  const { email, ...userData } = formData;
  const params = {
    username: email.toLowerCase(),
    password: generatePassword(),
    clientMetadata: {
      eventId,
      groupId: eventId,
      ...userData,
      ...context,
    },
    validationData: [],
  };

  const payload = {
    email,
    eventId,
    ...userData,
    ...context,
  };
  try {
    await trackMetric({
      metricType: "registration",
      payload
    });

    await cognitoSignUp(params);

    return logInWithEmail({ email }, () => {
      throw new Error(
        "Registration was completed but failed to auto sign-in. Please try to login."
      );
    });
  } catch (error: any) {
    if (error?.name !== "UsernameExistsException") {
      onFailure && onFailure(error?.message);
      throw Error(error?.message);
    }

    await trackMetric({
      metricType: "registration",
      payload,
    });

    return logInWithEmail({ email }, () => {
      throw new Error(
        "Registration was completed but failed to auto sign-in. Please try to login."
      );
    });
  }
};

export const logIn = async ({
  username,
  password,
}: {
  username: string;
  password: string;
}) => {
  return null;
};

export const logInWithEmail = async (
  { email }: { email: string },
  onFailure: Function
) => {
  const params = {
    username: email.toLowerCase(),
    password: "",
    validationData: {
      eventId,
    },
  };
  const user = await cognitoSignIn(params);
  user &&
    trackMetric({
      metricType: "login",
      payload: {
        email: email.toLowerCase(),
      },
    });
  return user;
};

export const logOut = async () => {
  return cognitoSignOut();
};

export const currentSession = async () => {};

enum AuthErrorStrings {
  InvalidEmail = "Incorrect username or password.",
}

export const handleSignUpErrors = (error: any) => {
  let message = error?.message || "Error during Auth.SignUp";
  switch (message) {
    case AuthErrorStrings.InvalidEmail:
      message = "Invalid email, please try again";
      break;
    default:
      break;
  }
  throw new Error(message);
};
