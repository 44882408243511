import create from "zustand";
import { persist } from "zustand/middleware";

interface Session {
  title: string;
  startTime: string;
  endTime: string;
  breakout?: string;
  abstract?: string;
  speakers?: {
    name: string;
    title: string;
    image?: string;
    isModerator?: boolean;
  }[];
  sponsor?: string;
  tile?: { slug?: string };
}

interface SessionState {
  selectedSessions: Session[];
  sessionCount: number;
  addSession: (session: Session) => void;
  removeSession: (sessionTitle: string) => void;
  toggleSession: (session: Session) => void;
  isSelected: (sessionTitle: string) => boolean;
  resetSessions: () => void; // New method added here
}

export const useSessionStore = create<SessionState>()(
  persist(
    (set, get) => ({
      selectedSessions: [],
      sessionCount: 0,
      addSession: (session) =>
        set((state) => {
          const updatedSessions = [...state.selectedSessions, session];
          return {
            selectedSessions: updatedSessions,
            sessionCount: updatedSessions.length,
          };
        }),
      removeSession: (sessionTitle) =>
        set((state) => {
          const updatedSessions = state.selectedSessions.filter(
            (session) => session.title !== sessionTitle
          );
          return {
            selectedSessions: updatedSessions,
            sessionCount: updatedSessions.length,
          };
        }),
      toggleSession: (session) =>
        set((state) => {
          const isSelected = state.selectedSessions.some(
            (s) => s.title === session.title
          );
          const updatedSessions = isSelected
            ? state.selectedSessions.filter((s) => s.title !== session.title)
            : [...state.selectedSessions, session];
          return {
            selectedSessions: updatedSessions,
            sessionCount: updatedSessions.length,
          };
        }),
      isSelected: (sessionTitle: string) => {
        const state = get();
        return state.selectedSessions.some(
          (session) => session.title === sessionTitle
        );
      },
      resetSessions: () =>
        set(() => ({
          selectedSessions: [],
          sessionCount: 0,
        })),
    }),
    {
      name: "session-storage", // unique name for the storage key
      getStorage: () => localStorage, // define the storage location
    }
  )
);

export default useSessionStore;
