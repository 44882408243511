import { generateClient } from "@aws-amplify/api";
import axios from "axios";
import { getCurrentUser } from 'aws-amplify/auth';
import { utmCodes } from "../store/useUtmCodeStore";

const api_endpoint = process.env.GATSBY_BACKEND_API_ENDPOINT;
const eventId = process.env.GATSBY_EVENT_ID;
const organizationId = process.env.GATSBY_ORGANIZATION_ID;

interface Metric {
  metricType: string;
  payload: any;
}

export const getAttendeeByEmail = async (eventId: Record<string, unknown>, email: string) => {
  if (!eventId || !email) {
    throw new Error("eventId and email can not be null");
  }

  let response = await fetch(`${api_endpoint}/attendee/${eventId}/${email}`);
  if (!response.ok) {
    console.error(response.text());
    return null;
  }
  return response.json();
};

export const registerAttendee = async (eventId: Record<string, unknown>, attendee: any) => {
  let response = await fetch(`${api_endpoint}/attendee/${eventId}`, {
    method: "POST",
    body: JSON.stringify({ attendee: attendee }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.json();
};

export const getAttendeesByOrder = async (orderId: string) => {
  let response = await fetch(`${api_endpoint}/attendees_by_order/${orderId}`);
  if (!response.ok) {
    console.error(response.text());
    return null;
  }
  return response.json();
};

export const getAttendeesByOrderPromise = (orderId: string) => {
  return fetch(`${api_endpoint}/attendees_by_order/${orderId}`);
};

export const submitHubSpotForm = (formData: Record<string, unknown>, formId: string) => {
  if (!formId) {
    throw new Error("cannot submit form without a formId");
  }
};

export const getProfile = async () => {
  const { username } = await getCurrentUser();
  if (!username) {
    throw new Error("Failed to get profile. user not found");
  }
  const endpoint = `${api_endpoint}/${eventId}/profile`;
  return axios({
    method: "GET",
    url: endpoint,
    params: {
      email: username,
    },
  });
};

export interface UpodateProfileProps {
  teamSlug?: string;
  teamIconUrl?: string;
  currGameQuestionIndex?: number;
}

export const updateProfile = async ({
  teamSlug,
  teamIconUrl,
  currGameQuestionIndex,
}: UpodateProfileProps) => {
  const { username } = await getCurrentUser();
  const endpoint = `${api_endpoint}/${eventId}/profile`;
  return axios({
    method: "POST",
    url: endpoint,
    data: {
      email: username,
      teamSlug,
      teamIconUrl,
      currGameQuestionIndex,
    },
  });
};

export interface UpdateGameProps {
  teamSlug: string;
  gameId: string;
  score?: number;
}

export const updateGame = async ({
  teamSlug,
  gameId,
  score,
}: UpdateGameProps) => {
  const endpoint = `${api_endpoint}/${eventId}/game`;
  return axios({
    method: "POST",
    url: endpoint,
    data: {
      teamSlug,
      gameId,
      score,
    },
  });
};
export interface GetGameProps {
  gameId: string;
}

export const getGame = async ({ gameId }: GetGameProps) => {
  const endpoint = `${api_endpoint}/${eventId}/game`;
  return axios({
    method: "GET",
    url: endpoint,
    params: {
      gameId,
    },
  });
};

export interface BrightcoveVideo {
  id: string;
  account_id: string;
  updated_at: string;
  created_at: string;
  name: string;
  published_at: string;
  reference_id: string;
  schedule?: null | { ends_at?: string | null; starts_at?: string | null };
  state: "ACTIVE" | "INACTIVE";
}

export interface BrightcovePlaylistResponse {
  data: BrightcoveVideo[];
}

export const getPlaylist = async (
  playlistId: string
): Promise<BrightcovePlaylistResponse> => {
  const endpoint = `${api_endpoint}/${eventId}/playlist/${playlistId}`;
  return (await fetch(endpoint)).json();
};

export const trackMetric = async ({ metricType, payload }: Metric) => {
  const endpoint = `${api_endpoint}/${eventId}/analytics`;
  const utm = utmCodes();
  let username;
  try {
    const user = await getCurrentUser();
    username = user.username;
  } catch (error) {
    username = "";
  }
  const body = {
    metricType,
    payload: { ...payload, username, ...utm },
    organizationId,
  };
  try {
    await fetch(endpoint, {
      method: "POST",
      body: JSON.stringify(body),
      headers: { "Content-type": "application/json; charset=UTF-8" },
    });
  } catch (error) {
    console.error(error);
  }
};

export const trackPageview = async (url: string) => {
  trackMetric({
    metricType: "pageview",
    payload: {
      url,
    },
  });
};

export const getTweets = async (query: string) => {
  // const tweetRequest = new Request("https://api.upgather.com/tweets.json?q=" + encodeURIComponent(query));
  // return fetch(tweetRequest);
  return new Promise(
    (resolve: (tweetJson: string) => void, reject?: (error: any) => void) => {
      setTimeout(() => {
        // Temporary test data
        resolve(
          JSON.stringify({
            statuses: [
              {
                created_at: "Tue May 18 15:53:02 +0000 2021",
                id: 1394682449813282817,
                id_str: "1394682449813282817",
                full_text:
                  "RT @fedscoop: Next, The Role of Data in Crisis Response &amp; Resilience ft: @RajivRao_CTO, CTO, @NYGov, and @EvaNPereira, Deputy Chief Data Of…",
                truncated: false,
                display_text_range: [0, 144],
                entities: {
                  hashtags: [],
                  symbols: [],
                  user_mentions: [
                    {
                      screen_name: "fedscoop",
                      name: "FedScoop",
                      id: 1477949676,
                      id_str: "1477949676",
                      indices: [3, 12],
                    },
                    {
                      screen_name: "RajivRao_CTO",
                      name: "Rajiv Rao",
                      id: 2553719442,
                      id_str: "2553719442",
                      indices: [77, 90],
                    },
                    {
                      screen_name: "NYGov",
                      name: "State of New York 😷",
                      id: 2815794381,
                      id_str: "2815794381",
                      indices: [97, 103],
                    },
                    {
                      screen_name: "EvaNPereira",
                      name: "Eva Pereira",
                      id: 104295317,
                      id_str: "104295317",
                      indices: [109, 121],
                    },
                  ],
                  urls: [],
                },
                metadata: {
                  iso_language_code: "en",
                  result_type: "recent",
                },
                source:
                  '<a href="http://twitter.com/download/iphone" rel="nofollow">Twitter for iPhone</a>',
                in_reply_to_status_id: null,
                in_reply_to_status_id_str: null,
                in_reply_to_user_id: null,
                in_reply_to_user_id_str: null,
                in_reply_to_screen_name: null,
                user: {
                  id: 467547704,
                  id_str: "467547704",
                  name: "Tino Mathew",
                  screen_name: "nyctino",
                  location: "Dallas, TX",
                  description:
                    "Family Man and IT Enthusiast - Customer Focused Solutions Professional - All around Happy Guy",
                  url: null,
                  entities: {
                    description: {
                      urls: [],
                    },
                  },
                  protected: false,
                  followers_count: 307,
                  friends_count: 292,
                  listed_count: 8,
                  created_at: "Wed Jan 18 15:52:17 +0000 2012",
                  favourites_count: 276,
                  utc_offset: null,
                  time_zone: null,
                  geo_enabled: false,
                  verified: false,
                  statuses_count: 205,
                  lang: null,
                  contributors_enabled: false,
                  is_translator: false,
                  is_translation_enabled: false,
                  profile_background_color: "000000",
                  profile_background_image_url:
                    "http://abs.twimg.com/images/themes/theme1/bg.png",
                  profile_background_image_url_https:
                    "https://abs.twimg.com/images/themes/theme1/bg.png",
                  profile_background_tile: false,
                  profile_image_url:
                    "http://pbs.twimg.com/profile_images/938097233119010816/7Bau0YVj_normal.jpg",
                  profile_image_url_https:
                    "https://pbs.twimg.com/profile_images/938097233119010816/7Bau0YVj_normal.jpg",
                  profile_banner_url:
                    "https://pbs.twimg.com/profile_banners/467547704/1441304074",
                  profile_link_color: "3B94D9",
                  profile_sidebar_border_color: "000000",
                  profile_sidebar_fill_color: "000000",
                  profile_text_color: "000000",
                  profile_use_background_image: false,
                  has_extended_profile: true,
                  default_profile: false,
                  default_profile_image: false,
                  following: null,
                  follow_request_sent: null,
                  notifications: null,
                  translator_type: "none",
                  withheld_in_countries: [],
                },
                geo: null,
                coordinates: null,
                place: null,
                contributors: null,
                retweeted_status: {
                  created_at: "Tue May 18 15:47:01 +0000 2021",
                  id: 1394680933803040768,
                  id_str: "1394680933803040768",
                  full_text:
                    "Next, The Role of Data in Crisis Response &amp; Resilience ft: @RajivRao_CTO, CTO, @NYGov, and @EvaNPereira, Deputy Chief Data Officer, @LACity.\nModerated by @nyctino, Director, Public Sector Solution Consulting, @Informatica. #DatainAction https://t.co/bkHztv4NRm",
                  truncated: false,
                  display_text_range: [0, 240],
                  entities: {
                    hashtags: [
                      {
                        text: "DatainAction",
                        indices: [227, 240],
                      },
                    ],
                    symbols: [],
                    user_mentions: [
                      {
                        screen_name: "RajivRao_CTO",
                        name: "Rajiv Rao",
                        id: 2553719442,
                        id_str: "2553719442",
                        indices: [63, 76],
                      },
                      {
                        screen_name: "NYGov",
                        name: "State of New York 😷",
                        id: 2815794381,
                        id_str: "2815794381",
                        indices: [83, 89],
                      },
                      {
                        screen_name: "EvaNPereira",
                        name: "Eva Pereira",
                        id: 104295317,
                        id_str: "104295317",
                        indices: [95, 107],
                      },
                      {
                        screen_name: "LACity",
                        name: "City of Los Angeles",
                        id: 880028463704363008,
                        id_str: "880028463704363008",
                        indices: [136, 143],
                      },
                      {
                        screen_name: "nyctino",
                        name: "Tino Mathew",
                        id: 467547704,
                        id_str: "467547704",
                        indices: [158, 166],
                      },
                      {
                        screen_name: "Informatica",
                        name: "Informatica",
                        id: 17350542,
                        id_str: "17350542",
                        indices: [213, 225],
                      },
                    ],
                    urls: [],
                    media: [
                      {
                        id: 1394680931923922951,
                        id_str: "1394680931923922951",
                        indices: [241, 264],
                        media_url:
                          "http://pbs.twimg.com/media/E1rm6xJWEAc93a4.png",
                        media_url_https:
                          "https://pbs.twimg.com/media/E1rm6xJWEAc93a4.png",
                        url: "https://t.co/bkHztv4NRm",
                        display_url: "pic.twitter.com/bkHztv4NRm",
                        expanded_url:
                          "https://twitter.com/fedscoop/status/1394680933803040768/photo/1",
                        type: "photo",
                        sizes: {
                          medium: {
                            w: 1200,
                            h: 675,
                            resize: "fit",
                          },
                          thumb: {
                            w: 150,
                            h: 150,
                            resize: "crop",
                          },
                          large: {
                            w: 1200,
                            h: 675,
                            resize: "fit",
                          },
                          small: {
                            w: 680,
                            h: 383,
                            resize: "fit",
                          },
                        },
                      },
                    ],
                  },
                  extended_entities: {
                    media: [
                      {
                        id: 1394680931923922951,
                        id_str: "1394680931923922951",
                        indices: [241, 264],
                        media_url:
                          "http://pbs.twimg.com/media/E1rm6xJWEAc93a4.png",
                        media_url_https:
                          "https://pbs.twimg.com/media/E1rm6xJWEAc93a4.png",
                        url: "https://t.co/bkHztv4NRm",
                        display_url: "pic.twitter.com/bkHztv4NRm",
                        expanded_url:
                          "https://twitter.com/fedscoop/status/1394680933803040768/photo/1",
                        type: "photo",
                        sizes: {
                          medium: {
                            w: 1200,
                            h: 675,
                            resize: "fit",
                          },
                          thumb: {
                            w: 150,
                            h: 150,
                            resize: "crop",
                          },
                          large: {
                            w: 1200,
                            h: 675,
                            resize: "fit",
                          },
                          small: {
                            w: 680,
                            h: 383,
                            resize: "fit",
                          },
                        },
                      },
                    ],
                  },
                  metadata: {
                    iso_language_code: "en",
                    result_type: "recent",
                  },
                  source:
                    '<a href="http://www.hubspot.com/" rel="nofollow">HubSpot</a>',
                  in_reply_to_status_id: null,
                  in_reply_to_status_id_str: null,
                  in_reply_to_user_id: null,
                  in_reply_to_user_id_str: null,
                  in_reply_to_screen_name: null,
                  user: {
                    id: 1477949676,
                    id_str: "1477949676",
                    name: "FedScoop",
                    screen_name: "fedscoop",
                    location: "Washington, D.C.",
                    description:
                      "FedScoop, a @ScoopNewsGroup property, gathers leaders from federal agencies, academia and the tech industry to discuss ways technology can improve government",
                    url: "https://t.co/hwDbuHa7Px",
                    entities: {
                      url: {
                        urls: [
                          {
                            url: "https://t.co/hwDbuHa7Px",
                            expanded_url: "http://www.fedscoop.com",
                            display_url: "fedscoop.com",
                            indices: [0, 23],
                          },
                        ],
                      },
                      description: {
                        urls: [],
                      },
                    },
                    protected: false,
                    followers_count: 13857,
                    friends_count: 359,
                    listed_count: 563,
                    created_at: "Sun Jun 02 18:58:39 +0000 2013",
                    favourites_count: 5361,
                    utc_offset: null,
                    time_zone: null,
                    geo_enabled: true,
                    verified: false,
                    statuses_count: 29929,
                    lang: null,
                    contributors_enabled: false,
                    is_translator: false,
                    is_translation_enabled: false,
                    profile_background_color: "000000",
                    profile_background_image_url:
                      "http://abs.twimg.com/images/themes/theme1/bg.png",
                    profile_background_image_url_https:
                      "https://abs.twimg.com/images/themes/theme1/bg.png",
                    profile_background_tile: false,
                    profile_image_url:
                      "http://pbs.twimg.com/profile_images/1354826965044363271/bnl5hXcb_normal.jpg",
                    profile_image_url_https:
                      "https://pbs.twimg.com/profile_images/1354826965044363271/bnl5hXcb_normal.jpg",
                    profile_banner_url:
                      "https://pbs.twimg.com/profile_banners/1477949676/1552512995",
                    profile_link_color: "1B95E0",
                    profile_sidebar_border_color: "000000",
                    profile_sidebar_fill_color: "000000",
                    profile_text_color: "000000",
                    profile_use_background_image: false,
                    has_extended_profile: false,
                    default_profile: false,
                    default_profile_image: false,
                    following: null,
                    follow_request_sent: null,
                    notifications: null,
                    translator_type: "none",
                    withheld_in_countries: [],
                  },
                  geo: null,
                  coordinates: null,
                  place: null,
                  contributors: null,
                  is_quote_status: false,
                  retweet_count: 1,
                  favorite_count: 1,
                  favorited: false,
                  retweeted: false,
                  possibly_sensitive: false,
                  lang: "en",
                },
                is_quote_status: false,
                retweet_count: 1,
                favorite_count: 0,
                favorited: false,
                retweeted: false,
                lang: "en",
              },
              {
                created_at: "Tue May 18 15:47:01 +0000 2021",
                id: 1394680933803040768,
                id_str: "1394680933803040768",
                full_text:
                  "Next, The Role of Data in Crisis Response &amp; Resilience ft: @RajivRao_CTO, CTO, @NYGov, and @EvaNPereira, Deputy Chief Data Officer, @LACity.\nModerated by @nyctino, Director, Public Sector Solution Consulting, @Informatica. #DatainAction https://t.co/bkHztv4NRm",
                truncated: false,
                display_text_range: [0, 240],
                entities: {
                  hashtags: [
                    {
                      text: "DatainAction",
                      indices: [227, 240],
                    },
                  ],
                  symbols: [],
                  user_mentions: [
                    {
                      screen_name: "RajivRao_CTO",
                      name: "Rajiv Rao",
                      id: 2553719442,
                      id_str: "2553719442",
                      indices: [63, 76],
                    },
                    {
                      screen_name: "NYGov",
                      name: "State of New York 😷",
                      id: 2815794381,
                      id_str: "2815794381",
                      indices: [83, 89],
                    },
                    {
                      screen_name: "EvaNPereira",
                      name: "Eva Pereira",
                      id: 104295317,
                      id_str: "104295317",
                      indices: [95, 107],
                    },
                    {
                      screen_name: "LACity",
                      name: "City of Los Angeles",
                      id: 880028463704363008,
                      id_str: "880028463704363008",
                      indices: [136, 143],
                    },
                    {
                      screen_name: "nyctino",
                      name: "Tino Mathew",
                      id: 467547704,
                      id_str: "467547704",
                      indices: [158, 166],
                    },
                    {
                      screen_name: "Informatica",
                      name: "Informatica",
                      id: 17350542,
                      id_str: "17350542",
                      indices: [213, 225],
                    },
                  ],
                  urls: [],
                  media: [
                    {
                      id: 1394680931923922951,
                      id_str: "1394680931923922951",
                      indices: [241, 264],
                      media_url:
                        "http://pbs.twimg.com/media/E1rm6xJWEAc93a4.png",
                      media_url_https:
                        "https://pbs.twimg.com/media/E1rm6xJWEAc93a4.png",
                      url: "https://t.co/bkHztv4NRm",
                      display_url: "pic.twitter.com/bkHztv4NRm",
                      expanded_url:
                        "https://twitter.com/fedscoop/status/1394680933803040768/photo/1",
                      type: "photo",
                      sizes: {
                        medium: {
                          w: 1200,
                          h: 675,
                          resize: "fit",
                        },
                        thumb: {
                          w: 150,
                          h: 150,
                          resize: "crop",
                        },
                        large: {
                          w: 1200,
                          h: 675,
                          resize: "fit",
                        },
                        small: {
                          w: 680,
                          h: 383,
                          resize: "fit",
                        },
                      },
                    },
                  ],
                },
                extended_entities: {
                  media: [
                    {
                      id: 1394680931923922951,
                      id_str: "1394680931923922951",
                      indices: [241, 264],
                      media_url:
                        "http://pbs.twimg.com/media/E1rm6xJWEAc93a4.png",
                      media_url_https:
                        "https://pbs.twimg.com/media/E1rm6xJWEAc93a4.png",
                      url: "https://t.co/bkHztv4NRm",
                      display_url: "pic.twitter.com/bkHztv4NRm",
                      expanded_url:
                        "https://twitter.com/fedscoop/status/1394680933803040768/photo/1",
                      type: "photo",
                      sizes: {
                        medium: {
                          w: 1200,
                          h: 675,
                          resize: "fit",
                        },
                        thumb: {
                          w: 150,
                          h: 150,
                          resize: "crop",
                        },
                        large: {
                          w: 1200,
                          h: 675,
                          resize: "fit",
                        },
                        small: {
                          w: 680,
                          h: 383,
                          resize: "fit",
                        },
                      },
                    },
                  ],
                },
                metadata: {
                  iso_language_code: "en",
                  result_type: "recent",
                },
                source:
                  '<a href="http://www.hubspot.com/" rel="nofollow">HubSpot</a>',
                in_reply_to_status_id: null,
                in_reply_to_status_id_str: null,
                in_reply_to_user_id: null,
                in_reply_to_user_id_str: null,
                in_reply_to_screen_name: null,
                user: {
                  id: 1477949676,
                  id_str: "1477949676",
                  name: "FedScoop",
                  screen_name: "fedscoop",
                  location: "Washington, D.C.",
                  description:
                    "FedScoop, a @ScoopNewsGroup property, gathers leaders from federal agencies, academia and the tech industry to discuss ways technology can improve government",
                  url: "https://t.co/hwDbuHa7Px",
                  entities: {
                    url: {
                      urls: [
                        {
                          url: "https://t.co/hwDbuHa7Px",
                          expanded_url: "http://www.fedscoop.com",
                          display_url: "fedscoop.com",
                          indices: [0, 23],
                        },
                      ],
                    },
                    description: {
                      urls: [],
                    },
                  },
                  protected: false,
                  followers_count: 13857,
                  friends_count: 359,
                  listed_count: 563,
                  created_at: "Sun Jun 02 18:58:39 +0000 2013",
                  favourites_count: 5361,
                  utc_offset: null,
                  time_zone: null,
                  geo_enabled: true,
                  verified: false,
                  statuses_count: 29929,
                  lang: null,
                  contributors_enabled: false,
                  is_translator: false,
                  is_translation_enabled: false,
                  profile_background_color: "000000",
                  profile_background_image_url:
                    "http://abs.twimg.com/images/themes/theme1/bg.png",
                  profile_background_image_url_https:
                    "https://abs.twimg.com/images/themes/theme1/bg.png",
                  profile_background_tile: false,
                  profile_image_url:
                    "http://pbs.twimg.com/profile_images/1354826965044363271/bnl5hXcb_normal.jpg",
                  profile_image_url_https:
                    "https://pbs.twimg.com/profile_images/1354826965044363271/bnl5hXcb_normal.jpg",
                  profile_banner_url:
                    "https://pbs.twimg.com/profile_banners/1477949676/1552512995",
                  profile_link_color: "1B95E0",
                  profile_sidebar_border_color: "000000",
                  profile_sidebar_fill_color: "000000",
                  profile_text_color: "000000",
                  profile_use_background_image: false,
                  has_extended_profile: false,
                  default_profile: false,
                  default_profile_image: false,
                  following: null,
                  follow_request_sent: null,
                  notifications: null,
                  translator_type: "none",
                  withheld_in_countries: [],
                },
                geo: null,
                coordinates: null,
                place: null,
                contributors: null,
                is_quote_status: false,
                retweet_count: 1,
                favorite_count: 1,
                favorited: false,
                retweeted: false,
                possibly_sensitive: false,
                lang: "en",
              },
              {
                created_at: "Tue May 18 15:43:59 +0000 2021",
                id: 1394680169974087685,
                id_str: "1394680169974087685",
                full_text:
                  "RT @Samu3lR0y: DOD takes automation a step further with machine learning - FedScoop\n\nRead more here: https://t.co/Ymbo0NInCp\n\n#ArtificialIn…",
                truncated: false,
                display_text_range: [0, 140],
                entities: {
                  hashtags: [],
                  symbols: [],
                  user_mentions: [
                    {
                      screen_name: "Samu3lR0y",
                      name: "Samuel Roy",
                      id: 934888860168925186,
                      id_str: "934888860168925186",
                      indices: [3, 13],
                    },
                  ],
                  urls: [
                    {
                      url: "https://t.co/Ymbo0NInCp",
                      expanded_url: "https://ift.tt/33zuBvt",
                      display_url: "ift.tt/33zuBvt",
                      indices: [101, 124],
                    },
                  ],
                },
                metadata: {
                  iso_language_code: "en",
                  result_type: "recent",
                },
                source:
                  '<a href="https://hitalfashion.in" rel="nofollow">python_expert</a>',
                in_reply_to_status_id: null,
                in_reply_to_status_id_str: null,
                in_reply_to_user_id: null,
                in_reply_to_user_id_str: null,
                in_reply_to_screen_name: null,
                user: {
                  id: 1151494493054484481,
                  id_str: "1151494493054484481",
                  name: "Python Expert",
                  screen_name: "PythonExpertBot",
                  location: "127.0.0.1",
                  description:
                    "I am a bot created by @YogeshNile using #PythonProgramming language.",
                  url: null,
                  entities: {
                    description: {
                      urls: [],
                    },
                  },
                  protected: false,
                  followers_count: 677,
                  friends_count: 1,
                  listed_count: 21,
                  created_at: "Wed Jul 17 14:10:55 +0000 2019",
                  favourites_count: 158,
                  utc_offset: null,
                  time_zone: null,
                  geo_enabled: false,
                  verified: false,
                  statuses_count: 128617,
                  lang: null,
                  contributors_enabled: false,
                  is_translator: false,
                  is_translation_enabled: false,
                  profile_background_color: "F5F8FA",
                  profile_background_image_url: null,
                  profile_background_image_url_https: null,
                  profile_background_tile: false,
                  profile_image_url:
                    "http://pbs.twimg.com/profile_images/1278999198176665602/o3B15TSY_normal.jpg",
                  profile_image_url_https:
                    "https://pbs.twimg.com/profile_images/1278999198176665602/o3B15TSY_normal.jpg",
                  profile_banner_url:
                    "https://pbs.twimg.com/profile_banners/1151494493054484481/1593772150",
                  profile_link_color: "1DA1F2",
                  profile_sidebar_border_color: "C0DEED",
                  profile_sidebar_fill_color: "DDEEF6",
                  profile_text_color: "333333",
                  profile_use_background_image: true,
                  has_extended_profile: false,
                  default_profile: true,
                  default_profile_image: false,
                  following: null,
                  follow_request_sent: null,
                  notifications: null,
                  translator_type: "none",
                  withheld_in_countries: [],
                },
                geo: null,
                coordinates: null,
                place: null,
                contributors: null,
                retweeted_status: {
                  created_at: "Tue May 18 15:43:33 +0000 2021",
                  id: 1394680064428679179,
                  id_str: "1394680064428679179",
                  full_text:
                    "DOD takes automation a step further with machine learning - FedScoop\n\nRead more here: https://t.co/Ymbo0NInCp\n\n#ArtificialIntelligence #AI #DataScience #MachineLearning #BigData #DeepLearning #NLP #Robots #IoT",
                  truncated: false,
                  display_text_range: [0, 209],
                  entities: {
                    hashtags: [
                      {
                        text: "ArtificialIntelligence",
                        indices: [111, 134],
                      },
                      {
                        text: "AI",
                        indices: [135, 138],
                      },
                      {
                        text: "DataScience",
                        indices: [139, 151],
                      },
                      {
                        text: "MachineLearning",
                        indices: [152, 168],
                      },
                      {
                        text: "BigData",
                        indices: [169, 177],
                      },
                      {
                        text: "DeepLearning",
                        indices: [178, 191],
                      },
                      {
                        text: "NLP",
                        indices: [192, 196],
                      },
                      {
                        text: "Robots",
                        indices: [197, 204],
                      },
                      {
                        text: "IoT",
                        indices: [205, 209],
                      },
                    ],
                    symbols: [],
                    user_mentions: [],
                    urls: [
                      {
                        url: "https://t.co/Ymbo0NInCp",
                        expanded_url: "https://ift.tt/33zuBvt",
                        display_url: "ift.tt/33zuBvt",
                        indices: [86, 109],
                      },
                    ],
                  },
                  metadata: {
                    iso_language_code: "en",
                    result_type: "recent",
                  },
                  source:
                    '<a href="https://ifttt.com" rel="nofollow">IFTTT</a>',
                  in_reply_to_status_id: null,
                  in_reply_to_status_id_str: null,
                  in_reply_to_user_id: null,
                  in_reply_to_user_id_str: null,
                  in_reply_to_screen_name: null,
                  user: {
                    id: 934888860168925186,
                    id_str: "934888860168925186",
                    name: "Samuel Roy",
                    screen_name: "Samu3lR0y",
                    location: "Hillingdon, London",
                    description:
                      "#BigData enthusiast interested in #AI and applications in #Banking",
                    url: null,
                    entities: {
                      description: {
                        urls: [],
                      },
                    },
                    protected: false,
                    followers_count: 439,
                    friends_count: 179,
                    listed_count: 25,
                    created_at: "Sun Nov 26 20:57:28 +0000 2017",
                    favourites_count: 25375,
                    utc_offset: null,
                    time_zone: null,
                    geo_enabled: false,
                    verified: false,
                    statuses_count: 81572,
                    lang: null,
                    contributors_enabled: false,
                    is_translator: false,
                    is_translation_enabled: false,
                    profile_background_color: "F5F8FA",
                    profile_background_image_url: null,
                    profile_background_image_url_https: null,
                    profile_background_tile: false,
                    profile_image_url:
                      "http://pbs.twimg.com/profile_images/1355145970959458306/B4wfAGX9_normal.jpg",
                    profile_image_url_https:
                      "https://pbs.twimg.com/profile_images/1355145970959458306/B4wfAGX9_normal.jpg",
                    profile_banner_url:
                      "https://pbs.twimg.com/profile_banners/934888860168925186/1511730078",
                    profile_link_color: "1DA1F2",
                    profile_sidebar_border_color: "C0DEED",
                    profile_sidebar_fill_color: "DDEEF6",
                    profile_text_color: "333333",
                    profile_use_background_image: true,
                    has_extended_profile: true,
                    default_profile: true,
                    default_profile_image: false,
                    following: null,
                    follow_request_sent: null,
                    notifications: null,
                    translator_type: "none",
                    withheld_in_countries: [],
                  },
                  geo: null,
                  coordinates: null,
                  place: null,
                  contributors: null,
                  is_quote_status: false,
                  retweet_count: 2,
                  favorite_count: 0,
                  favorited: false,
                  retweeted: false,
                  possibly_sensitive: false,
                  lang: "en",
                },
                is_quote_status: false,
                retweet_count: 2,
                favorite_count: 0,
                favorited: false,
                retweeted: false,
                possibly_sensitive: false,
                lang: "en",
              },
              {
                created_at: "Tue May 18 15:43:39 +0000 2021",
                id: 1394680088785047552,
                id_str: "1394680088785047552",
                full_text:
                  "RT @Samu3lR0y: DOD takes automation a step further with machine learning - FedScoop\n\nRead more here: https://t.co/Ymbo0NInCp\n\n#ArtificialIn…",
                truncated: false,
                display_text_range: [0, 140],
                entities: {
                  hashtags: [],
                  symbols: [],
                  user_mentions: [
                    {
                      screen_name: "Samu3lR0y",
                      name: "Samuel Roy",
                      id: 934888860168925186,
                      id_str: "934888860168925186",
                      indices: [3, 13],
                    },
                  ],
                  urls: [
                    {
                      url: "https://t.co/Ymbo0NInCp",
                      expanded_url: "https://ift.tt/33zuBvt",
                      display_url: "ift.tt/33zuBvt",
                      indices: [101, 124],
                    },
                  ],
                },
                metadata: {
                  iso_language_code: "en",
                  result_type: "recent",
                },
                source:
                  '<a href="https://www.fabricio-dev.com.br/" rel="nofollow">twitter-bot-fab</a>',
                in_reply_to_status_id: null,
                in_reply_to_status_id_str: null,
                in_reply_to_user_id: null,
                in_reply_to_user_id_str: null,
                in_reply_to_screen_name: null,
                user: {
                  id: 1051649960,
                  id_str: "1051649960",
                  name: "Fabricio Cavalcante",
                  screen_name: "Fabriciosx",
                  location: "Rio de janeiro",
                  description: "",
                  url: null,
                  entities: {
                    description: {
                      urls: [],
                    },
                  },
                  protected: false,
                  followers_count: 2375,
                  friends_count: 108,
                  listed_count: 37,
                  created_at: "Tue Jan 01 02:32:07 +0000 2013",
                  favourites_count: 0,
                  utc_offset: null,
                  time_zone: null,
                  geo_enabled: true,
                  verified: false,
                  statuses_count: 392688,
                  lang: null,
                  contributors_enabled: false,
                  is_translator: false,
                  is_translation_enabled: false,
                  profile_background_color: "9FB272",
                  profile_background_image_url:
                    "http://abs.twimg.com/images/themes/theme1/bg.png",
                  profile_background_image_url_https:
                    "https://abs.twimg.com/images/themes/theme1/bg.png",
                  profile_background_tile: true,
                  profile_image_url:
                    "http://pbs.twimg.com/profile_images/1379573441876090882/OERRsfhv_normal.jpg",
                  profile_image_url_https:
                    "https://pbs.twimg.com/profile_images/1379573441876090882/OERRsfhv_normal.jpg",
                  profile_banner_url:
                    "https://pbs.twimg.com/profile_banners/1051649960/1357242372",
                  profile_link_color: "799A7B",
                  profile_sidebar_border_color: "D0C9AF",
                  profile_sidebar_fill_color: "292C25",
                  profile_text_color: "457079",
                  profile_use_background_image: true,
                  has_extended_profile: false,
                  default_profile: false,
                  default_profile_image: false,
                  following: null,
                  follow_request_sent: null,
                  notifications: null,
                  translator_type: "none",
                  withheld_in_countries: [],
                },
                geo: null,
                coordinates: null,
                place: null,
                contributors: null,
                retweeted_status: {
                  created_at: "Tue May 18 15:43:33 +0000 2021",
                  id: 1394680064428679179,
                  id_str: "1394680064428679179",
                  full_text:
                    "DOD takes automation a step further with machine learning - FedScoop\n\nRead more here: https://t.co/Ymbo0NInCp\n\n#ArtificialIntelligence #AI #DataScience #MachineLearning #BigData #DeepLearning #NLP #Robots #IoT",
                  truncated: false,
                  display_text_range: [0, 209],
                  entities: {
                    hashtags: [
                      {
                        text: "ArtificialIntelligence",
                        indices: [111, 134],
                      },
                      {
                        text: "AI",
                        indices: [135, 138],
                      },
                      {
                        text: "DataScience",
                        indices: [139, 151],
                      },
                      {
                        text: "MachineLearning",
                        indices: [152, 168],
                      },
                      {
                        text: "BigData",
                        indices: [169, 177],
                      },
                      {
                        text: "DeepLearning",
                        indices: [178, 191],
                      },
                      {
                        text: "NLP",
                        indices: [192, 196],
                      },
                      {
                        text: "Robots",
                        indices: [197, 204],
                      },
                      {
                        text: "IoT",
                        indices: [205, 209],
                      },
                    ],
                    symbols: [],
                    user_mentions: [],
                    urls: [
                      {
                        url: "https://t.co/Ymbo0NInCp",
                        expanded_url: "https://ift.tt/33zuBvt",
                        display_url: "ift.tt/33zuBvt",
                        indices: [86, 109],
                      },
                    ],
                  },
                  metadata: {
                    iso_language_code: "en",
                    result_type: "recent",
                  },
                  source:
                    '<a href="https://ifttt.com" rel="nofollow">IFTTT</a>',
                  in_reply_to_status_id: null,
                  in_reply_to_status_id_str: null,
                  in_reply_to_user_id: null,
                  in_reply_to_user_id_str: null,
                  in_reply_to_screen_name: null,
                  user: {
                    id: 934888860168925186,
                    id_str: "934888860168925186",
                    name: "Samuel Roy",
                    screen_name: "Samu3lR0y",
                    location: "Hillingdon, London",
                    description:
                      "#BigData enthusiast interested in #AI and applications in #Banking",
                    url: null,
                    entities: {
                      description: {
                        urls: [],
                      },
                    },
                    protected: false,
                    followers_count: 439,
                    friends_count: 179,
                    listed_count: 25,
                    created_at: "Sun Nov 26 20:57:28 +0000 2017",
                    favourites_count: 25375,
                    utc_offset: null,
                    time_zone: null,
                    geo_enabled: false,
                    verified: false,
                    statuses_count: 81572,
                    lang: null,
                    contributors_enabled: false,
                    is_translator: false,
                    is_translation_enabled: false,
                    profile_background_color: "F5F8FA",
                    profile_background_image_url: null,
                    profile_background_image_url_https: null,
                    profile_background_tile: false,
                    profile_image_url:
                      "http://pbs.twimg.com/profile_images/1355145970959458306/B4wfAGX9_normal.jpg",
                    profile_image_url_https:
                      "https://pbs.twimg.com/profile_images/1355145970959458306/B4wfAGX9_normal.jpg",
                    profile_banner_url:
                      "https://pbs.twimg.com/profile_banners/934888860168925186/1511730078",
                    profile_link_color: "1DA1F2",
                    profile_sidebar_border_color: "C0DEED",
                    profile_sidebar_fill_color: "DDEEF6",
                    profile_text_color: "333333",
                    profile_use_background_image: true,
                    has_extended_profile: true,
                    default_profile: true,
                    default_profile_image: false,
                    following: null,
                    follow_request_sent: null,
                    notifications: null,
                    translator_type: "none",
                    withheld_in_countries: [],
                  },
                  geo: null,
                  coordinates: null,
                  place: null,
                  contributors: null,
                  is_quote_status: false,
                  retweet_count: 2,
                  favorite_count: 0,
                  favorited: false,
                  retweeted: false,
                  possibly_sensitive: false,
                  lang: "en",
                },
                is_quote_status: false,
                retweet_count: 2,
                favorite_count: 0,
                favorited: false,
                retweeted: false,
                possibly_sensitive: false,
                lang: "en",
              },
              {
                created_at: "Tue May 18 15:43:33 +0000 2021",
                id: 1394680064428679179,
                id_str: "1394680064428679179",
                full_text:
                  "DOD takes automation a step further with machine learning - FedScoop\n\nRead more here: https://t.co/Ymbo0NInCp\n\n#ArtificialIntelligence #AI #DataScience #MachineLearning #BigData #DeepLearning #NLP #Robots #IoT",
                truncated: false,
                display_text_range: [0, 209],
                entities: {
                  hashtags: [
                    {
                      text: "ArtificialIntelligence",
                      indices: [111, 134],
                    },
                    {
                      text: "AI",
                      indices: [135, 138],
                    },
                    {
                      text: "DataScience",
                      indices: [139, 151],
                    },
                    {
                      text: "MachineLearning",
                      indices: [152, 168],
                    },
                    {
                      text: "BigData",
                      indices: [169, 177],
                    },
                    {
                      text: "DeepLearning",
                      indices: [178, 191],
                    },
                    {
                      text: "NLP",
                      indices: [192, 196],
                    },
                    {
                      text: "Robots",
                      indices: [197, 204],
                    },
                    {
                      text: "IoT",
                      indices: [205, 209],
                    },
                  ],
                  symbols: [],
                  user_mentions: [],
                  urls: [
                    {
                      url: "https://t.co/Ymbo0NInCp",
                      expanded_url: "https://ift.tt/33zuBvt",
                      display_url: "ift.tt/33zuBvt",
                      indices: [86, 109],
                    },
                  ],
                },
                metadata: {
                  iso_language_code: "en",
                  result_type: "recent",
                },
                source: '<a href="https://ifttt.com" rel="nofollow">IFTTT</a>',
                in_reply_to_status_id: null,
                in_reply_to_status_id_str: null,
                in_reply_to_user_id: null,
                in_reply_to_user_id_str: null,
                in_reply_to_screen_name: null,
                user: {
                  id: 934888860168925186,
                  id_str: "934888860168925186",
                  name: "Samuel Roy",
                  screen_name: "Samu3lR0y",
                  location: "Hillingdon, London",
                  description:
                    "#BigData enthusiast interested in #AI and applications in #Banking",
                  url: null,
                  entities: {
                    description: {
                      urls: [],
                    },
                  },
                  protected: false,
                  followers_count: 439,
                  friends_count: 179,
                  listed_count: 25,
                  created_at: "Sun Nov 26 20:57:28 +0000 2017",
                  favourites_count: 25375,
                  utc_offset: null,
                  time_zone: null,
                  geo_enabled: false,
                  verified: false,
                  statuses_count: 81572,
                  lang: null,
                  contributors_enabled: false,
                  is_translator: false,
                  is_translation_enabled: false,
                  profile_background_color: "F5F8FA",
                  profile_background_image_url: null,
                  profile_background_image_url_https: null,
                  profile_background_tile: false,
                  profile_image_url:
                    "http://pbs.twimg.com/profile_images/1355145970959458306/B4wfAGX9_normal.jpg",
                  profile_image_url_https:
                    "https://pbs.twimg.com/profile_images/1355145970959458306/B4wfAGX9_normal.jpg",
                  profile_banner_url:
                    "https://pbs.twimg.com/profile_banners/934888860168925186/1511730078",
                  profile_link_color: "1DA1F2",
                  profile_sidebar_border_color: "C0DEED",
                  profile_sidebar_fill_color: "DDEEF6",
                  profile_text_color: "333333",
                  profile_use_background_image: true,
                  has_extended_profile: true,
                  default_profile: true,
                  default_profile_image: false,
                  following: null,
                  follow_request_sent: null,
                  notifications: null,
                  translator_type: "none",
                  withheld_in_countries: [],
                },
                geo: null,
                coordinates: null,
                place: null,
                contributors: null,
                is_quote_status: false,
                retweet_count: 2,
                favorite_count: 0,
                favorited: false,
                retweeted: false,
                possibly_sensitive: false,
                lang: "en",
              },
              {
                created_at: "Tue May 18 15:39:02 +0000 2021",
                id: 1394678926698557446,
                id_str: "1394678926698557446",
                full_text:
                  "Continuous monitoring of critical infrastructure absent from cyber executive order\nhttps://t.co/p1qlkmlbkh https://t.co/lv0OU9i3yC",
                truncated: false,
                display_text_range: [0, 106],
                entities: {
                  hashtags: [],
                  symbols: [],
                  user_mentions: [],
                  urls: [
                    {
                      url: "https://t.co/p1qlkmlbkh",
                      expanded_url:
                        "https://www.fedscoop.com/continuous-monitoring-critical-infrastructure/",
                      display_url: "fedscoop.com/continuous-mon…",
                      indices: [83, 106],
                    },
                  ],
                  media: [
                    {
                      id: 1394678922160263173,
                      id_str: "1394678922160263173",
                      indices: [107, 130],
                      media_url:
                        "http://pbs.twimg.com/media/E1rlFyMWUAUYNDK.jpg",
                      media_url_https:
                        "https://pbs.twimg.com/media/E1rlFyMWUAUYNDK.jpg",
                      url: "https://t.co/lv0OU9i3yC",
                      display_url: "pic.twitter.com/lv0OU9i3yC",
                      expanded_url:
                        "https://twitter.com/fedscoop/status/1394678926698557446/photo/1",
                      type: "photo",
                      sizes: {
                        thumb: {
                          w: 150,
                          h: 150,
                          resize: "crop",
                        },
                        small: {
                          w: 680,
                          h: 453,
                          resize: "fit",
                        },
                        large: {
                          w: 1920,
                          h: 1280,
                          resize: "fit",
                        },
                        medium: {
                          w: 1200,
                          h: 800,
                          resize: "fit",
                        },
                      },
                    },
                  ],
                },
                extended_entities: {
                  media: [
                    {
                      id: 1394678922160263173,
                      id_str: "1394678922160263173",
                      indices: [107, 130],
                      media_url:
                        "http://pbs.twimg.com/media/E1rlFyMWUAUYNDK.jpg",
                      media_url_https:
                        "https://pbs.twimg.com/media/E1rlFyMWUAUYNDK.jpg",
                      url: "https://t.co/lv0OU9i3yC",
                      display_url: "pic.twitter.com/lv0OU9i3yC",
                      expanded_url:
                        "https://twitter.com/fedscoop/status/1394678926698557446/photo/1",
                      type: "photo",
                      sizes: {
                        thumb: {
                          w: 150,
                          h: 150,
                          resize: "crop",
                        },
                        small: {
                          w: 680,
                          h: 453,
                          resize: "fit",
                        },
                        large: {
                          w: 1920,
                          h: 1280,
                          resize: "fit",
                        },
                        medium: {
                          w: 1200,
                          h: 800,
                          resize: "fit",
                        },
                      },
                    },
                  ],
                },
                metadata: {
                  iso_language_code: "en",
                  result_type: "recent",
                },
                source:
                  '<a href="https://www.contentcal.io" rel="nofollow">ContentCal Studio</a>',
                in_reply_to_status_id: null,
                in_reply_to_status_id_str: null,
                in_reply_to_user_id: null,
                in_reply_to_user_id_str: null,
                in_reply_to_screen_name: null,
                user: {
                  id: 1477949676,
                  id_str: "1477949676",
                  name: "FedScoop",
                  screen_name: "fedscoop",
                  location: "Washington, D.C.",
                  description:
                    "FedScoop, a @ScoopNewsGroup property, gathers leaders from federal agencies, academia and the tech industry to discuss ways technology can improve government",
                  url: "https://t.co/hwDbuHa7Px",
                  entities: {
                    url: {
                      urls: [
                        {
                          url: "https://t.co/hwDbuHa7Px",
                          expanded_url: "http://www.fedscoop.com",
                          display_url: "fedscoop.com",
                          indices: [0, 23],
                        },
                      ],
                    },
                    description: {
                      urls: [],
                    },
                  },
                  protected: false,
                  followers_count: 13857,
                  friends_count: 359,
                  listed_count: 563,
                  created_at: "Sun Jun 02 18:58:39 +0000 2013",
                  favourites_count: 5361,
                  utc_offset: null,
                  time_zone: null,
                  geo_enabled: true,
                  verified: false,
                  statuses_count: 29929,
                  lang: null,
                  contributors_enabled: false,
                  is_translator: false,
                  is_translation_enabled: false,
                  profile_background_color: "000000",
                  profile_background_image_url:
                    "http://abs.twimg.com/images/themes/theme1/bg.png",
                  profile_background_image_url_https:
                    "https://abs.twimg.com/images/themes/theme1/bg.png",
                  profile_background_tile: false,
                  profile_image_url:
                    "http://pbs.twimg.com/profile_images/1354826965044363271/bnl5hXcb_normal.jpg",
                  profile_image_url_https:
                    "https://pbs.twimg.com/profile_images/1354826965044363271/bnl5hXcb_normal.jpg",
                  profile_banner_url:
                    "https://pbs.twimg.com/profile_banners/1477949676/1552512995",
                  profile_link_color: "1B95E0",
                  profile_sidebar_border_color: "000000",
                  profile_sidebar_fill_color: "000000",
                  profile_text_color: "000000",
                  profile_use_background_image: false,
                  has_extended_profile: false,
                  default_profile: false,
                  default_profile_image: false,
                  following: null,
                  follow_request_sent: null,
                  notifications: null,
                  translator_type: "none",
                  withheld_in_countries: [],
                },
                geo: null,
                coordinates: null,
                place: null,
                contributors: null,
                is_quote_status: false,
                retweet_count: 0,
                favorite_count: 0,
                favorited: false,
                retweeted: false,
                possibly_sensitive: false,
                lang: "en",
              },
              {
                created_at: "Tue May 18 15:37:04 +0000 2021",
                id: 1394678431170895872,
                id_str: "1394678431170895872",
                full_text:
                  "RT @fedscoop: Now, a panel discussion on Data to Enhance the Citizen Experience w/ Ervan Rodgers, State CIO &amp; Assistant Director, @ohgov, a…",
                truncated: false,
                display_text_range: [0, 144],
                entities: {
                  hashtags: [],
                  symbols: [],
                  user_mentions: [
                    {
                      screen_name: "fedscoop",
                      name: "FedScoop",
                      id: 1477949676,
                      id_str: "1477949676",
                      indices: [3, 12],
                    },
                    {
                      screen_name: "ohgov",
                      name: "Ohio.gov",
                      id: 41368231,
                      id_str: "41368231",
                      indices: [134, 140],
                    },
                  ],
                  urls: [],
                },
                metadata: {
                  iso_language_code: "en",
                  result_type: "recent",
                },
                source:
                  '<a href="http://twitter.com/download/android" rel="nofollow">Twitter for Android</a>',
                in_reply_to_status_id: null,
                in_reply_to_status_id_str: null,
                in_reply_to_user_id: null,
                in_reply_to_user_id_str: null,
                in_reply_to_screen_name: null,
                user: {
                  id: 830961122979241984,
                  id_str: "830961122979241984",
                  name: "Adita Karkera",
                  screen_name: "AKark_DataGirl",
                  location: "Little Rock, AR",
                  description:
                    "Govtech Top 25 DDD |Data Leader of Year (WIT) |CDO |Data Strategy |AI |Retweets don't=endorsements | #WomenInTech | #WomenInData @DeloitteGov @DeloitteAI",
                  url: "https://t.co/hLrpXD1H1j",
                  entities: {
                    url: {
                      urls: [
                        {
                          url: "https://t.co/hLrpXD1H1j",
                          expanded_url:
                            "https://www.linkedin.com/in/aditakarkera/",
                          display_url: "linkedin.com/in/aditakarker…",
                          indices: [0, 23],
                        },
                      ],
                    },
                    description: {
                      urls: [],
                    },
                  },
                  protected: false,
                  followers_count: 394,
                  friends_count: 760,
                  listed_count: 0,
                  created_at: "Mon Feb 13 02:05:45 +0000 2017",
                  favourites_count: 2200,
                  utc_offset: null,
                  time_zone: null,
                  geo_enabled: true,
                  verified: false,
                  statuses_count: 543,
                  lang: null,
                  contributors_enabled: false,
                  is_translator: false,
                  is_translation_enabled: false,
                  profile_background_color: "F5F8FA",
                  profile_background_image_url: null,
                  profile_background_image_url_https: null,
                  profile_background_tile: false,
                  profile_image_url:
                    "http://pbs.twimg.com/profile_images/1353919684945600512/KfDZa32R_normal.jpg",
                  profile_image_url_https:
                    "https://pbs.twimg.com/profile_images/1353919684945600512/KfDZa32R_normal.jpg",
                  profile_link_color: "1DA1F2",
                  profile_sidebar_border_color: "C0DEED",
                  profile_sidebar_fill_color: "DDEEF6",
                  profile_text_color: "333333",
                  profile_use_background_image: true,
                  has_extended_profile: false,
                  default_profile: true,
                  default_profile_image: false,
                  following: null,
                  follow_request_sent: null,
                  notifications: null,
                  translator_type: "none",
                  withheld_in_countries: [],
                },
                geo: null,
                coordinates: null,
                place: null,
                contributors: null,
                retweeted_status: {
                  created_at: "Tue May 18 15:06:05 +0000 2021",
                  id: 1394670632118403075,
                  id_str: "1394670632118403075",
                  full_text:
                    "Now, a panel discussion on Data to Enhance the Citizen Experience w/ Ervan Rodgers, State CIO &amp; Assistant Director, @ohgov, and Greg Smiley, CIO, @MyFDOT. Moderated by @AKark_DataGirl, Distinguished Data &amp; AI Architect; AI Institute for Gov't Fellow, @Deloitte. #DatainAction https://t.co/SHmFdy6pHz",
                  truncated: false,
                  display_text_range: [0, 283],
                  entities: {
                    hashtags: [
                      {
                        text: "DatainAction",
                        indices: [270, 283],
                      },
                    ],
                    symbols: [],
                    user_mentions: [
                      {
                        screen_name: "ohgov",
                        name: "Ohio.gov",
                        id: 41368231,
                        id_str: "41368231",
                        indices: [120, 126],
                      },
                      {
                        screen_name: "MyFDOT",
                        name: "FLORIDA DOT",
                        id: 184852821,
                        id_str: "184852821",
                        indices: [150, 157],
                      },
                      {
                        screen_name: "AKark_DataGirl",
                        name: "Adita Karkera",
                        id: 830961122979241984,
                        id_str: "830961122979241984",
                        indices: [172, 187],
                      },
                      {
                        screen_name: "Deloitte",
                        name: "Deloitte",
                        id: 8457092,
                        id_str: "8457092",
                        indices: [259, 268],
                      },
                    ],
                    urls: [],
                    media: [
                      {
                        id: 1394670629257887745,
                        id_str: "1394670629257887745",
                        indices: [284, 307],
                        media_url:
                          "http://pbs.twimg.com/media/E1rdjEuWQAEVnyw.png",
                        media_url_https:
                          "https://pbs.twimg.com/media/E1rdjEuWQAEVnyw.png",
                        url: "https://t.co/SHmFdy6pHz",
                        display_url: "pic.twitter.com/SHmFdy6pHz",
                        expanded_url:
                          "https://twitter.com/fedscoop/status/1394670632118403075/photo/1",
                        type: "photo",
                        sizes: {
                          small: {
                            w: 680,
                            h: 383,
                            resize: "fit",
                          },
                          medium: {
                            w: 1200,
                            h: 675,
                            resize: "fit",
                          },
                          large: {
                            w: 1200,
                            h: 675,
                            resize: "fit",
                          },
                          thumb: {
                            w: 150,
                            h: 150,
                            resize: "crop",
                          },
                        },
                      },
                    ],
                  },
                  extended_entities: {
                    media: [
                      {
                        id: 1394670629257887745,
                        id_str: "1394670629257887745",
                        indices: [284, 307],
                        media_url:
                          "http://pbs.twimg.com/media/E1rdjEuWQAEVnyw.png",
                        media_url_https:
                          "https://pbs.twimg.com/media/E1rdjEuWQAEVnyw.png",
                        url: "https://t.co/SHmFdy6pHz",
                        display_url: "pic.twitter.com/SHmFdy6pHz",
                        expanded_url:
                          "https://twitter.com/fedscoop/status/1394670632118403075/photo/1",
                        type: "photo",
                        sizes: {
                          small: {
                            w: 680,
                            h: 383,
                            resize: "fit",
                          },
                          medium: {
                            w: 1200,
                            h: 675,
                            resize: "fit",
                          },
                          large: {
                            w: 1200,
                            h: 675,
                            resize: "fit",
                          },
                          thumb: {
                            w: 150,
                            h: 150,
                            resize: "crop",
                          },
                        },
                      },
                    ],
                  },
                  metadata: {
                    iso_language_code: "en",
                    result_type: "recent",
                  },
                  source:
                    '<a href="http://www.hubspot.com/" rel="nofollow">HubSpot</a>',
                  in_reply_to_status_id: null,
                  in_reply_to_status_id_str: null,
                  in_reply_to_user_id: null,
                  in_reply_to_user_id_str: null,
                  in_reply_to_screen_name: null,
                  user: {
                    id: 1477949676,
                    id_str: "1477949676",
                    name: "FedScoop",
                    screen_name: "fedscoop",
                    location: "Washington, D.C.",
                    description:
                      "FedScoop, a @ScoopNewsGroup property, gathers leaders from federal agencies, academia and the tech industry to discuss ways technology can improve government",
                    url: "https://t.co/hwDbuHa7Px",
                    entities: {
                      url: {
                        urls: [
                          {
                            url: "https://t.co/hwDbuHa7Px",
                            expanded_url: "http://www.fedscoop.com",
                            display_url: "fedscoop.com",
                            indices: [0, 23],
                          },
                        ],
                      },
                      description: {
                        urls: [],
                      },
                    },
                    protected: false,
                    followers_count: 13857,
                    friends_count: 359,
                    listed_count: 563,
                    created_at: "Sun Jun 02 18:58:39 +0000 2013",
                    favourites_count: 5361,
                    utc_offset: null,
                    time_zone: null,
                    geo_enabled: true,
                    verified: false,
                    statuses_count: 29929,
                    lang: null,
                    contributors_enabled: false,
                    is_translator: false,
                    is_translation_enabled: false,
                    profile_background_color: "000000",
                    profile_background_image_url:
                      "http://abs.twimg.com/images/themes/theme1/bg.png",
                    profile_background_image_url_https:
                      "https://abs.twimg.com/images/themes/theme1/bg.png",
                    profile_background_tile: false,
                    profile_image_url:
                      "http://pbs.twimg.com/profile_images/1354826965044363271/bnl5hXcb_normal.jpg",
                    profile_image_url_https:
                      "https://pbs.twimg.com/profile_images/1354826965044363271/bnl5hXcb_normal.jpg",
                    profile_banner_url:
                      "https://pbs.twimg.com/profile_banners/1477949676/1552512995",
                    profile_link_color: "1B95E0",
                    profile_sidebar_border_color: "000000",
                    profile_sidebar_fill_color: "000000",
                    profile_text_color: "000000",
                    profile_use_background_image: false,
                    has_extended_profile: false,
                    default_profile: false,
                    default_profile_image: false,
                    following: null,
                    follow_request_sent: null,
                    notifications: null,
                    translator_type: "none",
                    withheld_in_countries: [],
                  },
                  geo: null,
                  coordinates: null,
                  place: null,
                  contributors: null,
                  is_quote_status: false,
                  retweet_count: 1,
                  favorite_count: 1,
                  favorited: false,
                  retweeted: false,
                  possibly_sensitive: false,
                  lang: "en",
                },
                is_quote_status: false,
                retweet_count: 1,
                favorite_count: 0,
                favorited: false,
                retweeted: false,
                lang: "en",
              },
              {
                created_at: "Tue May 18 15:23:04 +0000 2021",
                id: 1394674909721288710,
                id_str: "1394674909721288710",
                full_text:
                  "Up next, a panel on Data in Support of the Warfighter ft: Lt. Col. Tyrone Lewis, Chief Data Officer, Deputy Data Division Chief, @USArmy, and Mike Anderson, Chief Strategist, Public Sector, @Informatica. #DatainAction https://t.co/vp6v4b1yST",
                truncated: false,
                display_text_range: [0, 217],
                entities: {
                  hashtags: [
                    {
                      text: "DatainAction",
                      indices: [204, 217],
                    },
                  ],
                  symbols: [],
                  user_mentions: [
                    {
                      screen_name: "USArmy",
                      name: "U.S. Army",
                      id: 8775672,
                      id_str: "8775672",
                      indices: [129, 136],
                    },
                    {
                      screen_name: "Informatica",
                      name: "Informatica",
                      id: 17350542,
                      id_str: "17350542",
                      indices: [190, 202],
                    },
                  ],
                  urls: [],
                  media: [
                    {
                      id: 1394674907934560258,
                      id_str: "1394674907934560258",
                      indices: [218, 241],
                      media_url:
                        "http://pbs.twimg.com/media/E1rhcICXEAIJ2m4.png",
                      media_url_https:
                        "https://pbs.twimg.com/media/E1rhcICXEAIJ2m4.png",
                      url: "https://t.co/vp6v4b1yST",
                      display_url: "pic.twitter.com/vp6v4b1yST",
                      expanded_url:
                        "https://twitter.com/fedscoop/status/1394674909721288710/photo/1",
                      type: "photo",
                      sizes: {
                        small: {
                          w: 680,
                          h: 383,
                          resize: "fit",
                        },
                        medium: {
                          w: 1200,
                          h: 675,
                          resize: "fit",
                        },
                        large: {
                          w: 1200,
                          h: 675,
                          resize: "fit",
                        },
                        thumb: {
                          w: 150,
                          h: 150,
                          resize: "crop",
                        },
                      },
                    },
                  ],
                },
                extended_entities: {
                  media: [
                    {
                      id: 1394674907934560258,
                      id_str: "1394674907934560258",
                      indices: [218, 241],
                      media_url:
                        "http://pbs.twimg.com/media/E1rhcICXEAIJ2m4.png",
                      media_url_https:
                        "https://pbs.twimg.com/media/E1rhcICXEAIJ2m4.png",
                      url: "https://t.co/vp6v4b1yST",
                      display_url: "pic.twitter.com/vp6v4b1yST",
                      expanded_url:
                        "https://twitter.com/fedscoop/status/1394674909721288710/photo/1",
                      type: "photo",
                      sizes: {
                        small: {
                          w: 680,
                          h: 383,
                          resize: "fit",
                        },
                        medium: {
                          w: 1200,
                          h: 675,
                          resize: "fit",
                        },
                        large: {
                          w: 1200,
                          h: 675,
                          resize: "fit",
                        },
                        thumb: {
                          w: 150,
                          h: 150,
                          resize: "crop",
                        },
                      },
                    },
                  ],
                },
                metadata: {
                  iso_language_code: "en",
                  result_type: "recent",
                },
                source:
                  '<a href="http://www.hubspot.com/" rel="nofollow">HubSpot</a>',
                in_reply_to_status_id: null,
                in_reply_to_status_id_str: null,
                in_reply_to_user_id: null,
                in_reply_to_user_id_str: null,
                in_reply_to_screen_name: null,
                user: {
                  id: 1477949676,
                  id_str: "1477949676",
                  name: "FedScoop",
                  screen_name: "fedscoop",
                  location: "Washington, D.C.",
                  description:
                    "FedScoop, a @ScoopNewsGroup property, gathers leaders from federal agencies, academia and the tech industry to discuss ways technology can improve government",
                  url: "https://t.co/hwDbuHa7Px",
                  entities: {
                    url: {
                      urls: [
                        {
                          url: "https://t.co/hwDbuHa7Px",
                          expanded_url: "http://www.fedscoop.com",
                          display_url: "fedscoop.com",
                          indices: [0, 23],
                        },
                      ],
                    },
                    description: {
                      urls: [],
                    },
                  },
                  protected: false,
                  followers_count: 13857,
                  friends_count: 359,
                  listed_count: 563,
                  created_at: "Sun Jun 02 18:58:39 +0000 2013",
                  favourites_count: 5361,
                  utc_offset: null,
                  time_zone: null,
                  geo_enabled: true,
                  verified: false,
                  statuses_count: 29929,
                  lang: null,
                  contributors_enabled: false,
                  is_translator: false,
                  is_translation_enabled: false,
                  profile_background_color: "000000",
                  profile_background_image_url:
                    "http://abs.twimg.com/images/themes/theme1/bg.png",
                  profile_background_image_url_https:
                    "https://abs.twimg.com/images/themes/theme1/bg.png",
                  profile_background_tile: false,
                  profile_image_url:
                    "http://pbs.twimg.com/profile_images/1354826965044363271/bnl5hXcb_normal.jpg",
                  profile_image_url_https:
                    "https://pbs.twimg.com/profile_images/1354826965044363271/bnl5hXcb_normal.jpg",
                  profile_banner_url:
                    "https://pbs.twimg.com/profile_banners/1477949676/1552512995",
                  profile_link_color: "1B95E0",
                  profile_sidebar_border_color: "000000",
                  profile_sidebar_fill_color: "000000",
                  profile_text_color: "000000",
                  profile_use_background_image: false,
                  has_extended_profile: false,
                  default_profile: false,
                  default_profile_image: false,
                  following: null,
                  follow_request_sent: null,
                  notifications: null,
                  translator_type: "none",
                  withheld_in_countries: [],
                },
                geo: null,
                coordinates: null,
                place: null,
                contributors: null,
                is_quote_status: false,
                retweet_count: 0,
                favorite_count: 0,
                favorited: false,
                retweeted: false,
                possibly_sensitive: false,
                lang: "en",
              },
              {
                created_at: "Tue May 18 15:10:59 +0000 2021",
                id: 1394671865071812612,
                id_str: "1394671865071812612",
                full_text:
                  "Dr. LTC Lewis Is interviewed on the Importance’s and Strategies of Data Defense. \n\nHe airs today. \nEmail required \n\n#daddysgirl #ancestorsgocrazy #ShowMeState #MissouriNative #USArmy #ArmyBrat ! \n\nhttps://t.co/iqFkvIxtYy https://t.co/X0nTOU93MP",
                truncated: false,
                display_text_range: [0, 220],
                entities: {
                  hashtags: [
                    {
                      text: "daddysgirl",
                      indices: [116, 127],
                    },
                    {
                      text: "ancestorsgocrazy",
                      indices: [128, 145],
                    },
                    {
                      text: "ShowMeState",
                      indices: [146, 158],
                    },
                    {
                      text: "MissouriNative",
                      indices: [159, 174],
                    },
                    {
                      text: "USArmy",
                      indices: [175, 182],
                    },
                    {
                      text: "ArmyBrat",
                      indices: [183, 192],
                    },
                  ],
                  symbols: [],
                  user_mentions: [],
                  urls: [
                    {
                      url: "https://t.co/iqFkvIxtYy",
                      expanded_url:
                        "https://www.fedscoop.com/events/data-in-action-summit/",
                      display_url: "fedscoop.com/events/data-in…",
                      indices: [197, 220],
                    },
                  ],
                  media: [
                    {
                      id: 1394671861053722625,
                      id_str: "1394671861053722625",
                      indices: [221, 244],
                      media_url:
                        "http://pbs.twimg.com/media/E1reqxhXEAEWN41.jpg",
                      media_url_https:
                        "https://pbs.twimg.com/media/E1reqxhXEAEWN41.jpg",
                      url: "https://t.co/X0nTOU93MP",
                      display_url: "pic.twitter.com/X0nTOU93MP",
                      expanded_url:
                        "https://twitter.com/_teatimewithsis/status/1394671865071812612/photo/1",
                      type: "photo",
                      sizes: {
                        small: {
                          w: 314,
                          h: 680,
                          resize: "fit",
                        },
                        thumb: {
                          w: 150,
                          h: 150,
                          resize: "crop",
                        },
                        medium: {
                          w: 554,
                          h: 1200,
                          resize: "fit",
                        },
                        large: {
                          w: 828,
                          h: 1792,
                          resize: "fit",
                        },
                      },
                    },
                  ],
                },
                extended_entities: {
                  media: [
                    {
                      id: 1394671861053722625,
                      id_str: "1394671861053722625",
                      indices: [221, 244],
                      media_url:
                        "http://pbs.twimg.com/media/E1reqxhXEAEWN41.jpg",
                      media_url_https:
                        "https://pbs.twimg.com/media/E1reqxhXEAEWN41.jpg",
                      url: "https://t.co/X0nTOU93MP",
                      display_url: "pic.twitter.com/X0nTOU93MP",
                      expanded_url:
                        "https://twitter.com/_teatimewithsis/status/1394671865071812612/photo/1",
                      type: "photo",
                      sizes: {
                        small: {
                          w: 314,
                          h: 680,
                          resize: "fit",
                        },
                        thumb: {
                          w: 150,
                          h: 150,
                          resize: "crop",
                        },
                        medium: {
                          w: 554,
                          h: 1200,
                          resize: "fit",
                        },
                        large: {
                          w: 828,
                          h: 1792,
                          resize: "fit",
                        },
                      },
                    },
                  ],
                },
                metadata: {
                  iso_language_code: "en",
                  result_type: "recent",
                },
                source:
                  '<a href="http://twitter.com/download/iphone" rel="nofollow">Twitter for iPhone</a>',
                in_reply_to_status_id: null,
                in_reply_to_status_id_str: null,
                in_reply_to_user_id: null,
                in_reply_to_user_id_str: null,
                in_reply_to_screen_name: null,
                user: {
                  id: 1337231773416235011,
                  id_str: "1337231773416235011",
                  name: "Sista Tea",
                  screen_name: "_teatimewithsis",
                  location: "Neptune ",
                  description:
                    "Poetic Intuitive | Normally Awkward | Instagram @sistateauplifts | God’s Daughter",
                  url: null,
                  entities: {
                    description: {
                      urls: [],
                    },
                  },
                  protected: false,
                  followers_count: 61,
                  friends_count: 83,
                  listed_count: 0,
                  created_at: "Fri Dec 11 03:04:43 +0000 2020",
                  favourites_count: 2073,
                  utc_offset: null,
                  time_zone: null,
                  geo_enabled: true,
                  verified: false,
                  statuses_count: 3661,
                  lang: null,
                  contributors_enabled: false,
                  is_translator: false,
                  is_translation_enabled: false,
                  profile_background_color: "F5F8FA",
                  profile_background_image_url: null,
                  profile_background_image_url_https: null,
                  profile_background_tile: false,
                  profile_image_url:
                    "http://pbs.twimg.com/profile_images/1342110517691871232/-Mu4pIxG_normal.jpg",
                  profile_image_url_https:
                    "https://pbs.twimg.com/profile_images/1342110517691871232/-Mu4pIxG_normal.jpg",
                  profile_banner_url:
                    "https://pbs.twimg.com/profile_banners/1337231773416235011/1607657123",
                  profile_link_color: "1DA1F2",
                  profile_sidebar_border_color: "C0DEED",
                  profile_sidebar_fill_color: "DDEEF6",
                  profile_text_color: "333333",
                  profile_use_background_image: true,
                  has_extended_profile: true,
                  default_profile: true,
                  default_profile_image: false,
                  following: null,
                  follow_request_sent: null,
                  notifications: null,
                  translator_type: "none",
                  withheld_in_countries: [],
                },
                geo: null,
                coordinates: null,
                place: null,
                contributors: null,
                is_quote_status: false,
                retweet_count: 0,
                favorite_count: 0,
                favorited: false,
                retweeted: false,
                possibly_sensitive: false,
                lang: "en",
              },
              {
                created_at: "Tue May 18 15:06:05 +0000 2021",
                id: 1394670632118403075,
                id_str: "1394670632118403075",
                full_text:
                  "Now, a panel discussion on Data to Enhance the Citizen Experience w/ Ervan Rodgers, State CIO &amp; Assistant Director, @ohgov, and Greg Smiley, CIO, @MyFDOT. Moderated by @AKark_DataGirl, Distinguished Data &amp; AI Architect; AI Institute for Gov't Fellow, @Deloitte. #DatainAction https://t.co/SHmFdy6pHz",
                truncated: false,
                display_text_range: [0, 283],
                entities: {
                  hashtags: [
                    {
                      text: "DatainAction",
                      indices: [270, 283],
                    },
                  ],
                  symbols: [],
                  user_mentions: [
                    {
                      screen_name: "ohgov",
                      name: "Ohio.gov",
                      id: 41368231,
                      id_str: "41368231",
                      indices: [120, 126],
                    },
                    {
                      screen_name: "MyFDOT",
                      name: "FLORIDA DOT",
                      id: 184852821,
                      id_str: "184852821",
                      indices: [150, 157],
                    },
                    {
                      screen_name: "AKark_DataGirl",
                      name: "Adita Karkera",
                      id: 830961122979241984,
                      id_str: "830961122979241984",
                      indices: [172, 187],
                    },
                    {
                      screen_name: "Deloitte",
                      name: "Deloitte",
                      id: 8457092,
                      id_str: "8457092",
                      indices: [259, 268],
                    },
                  ],
                  urls: [],
                  media: [
                    {
                      id: 1394670629257887745,
                      id_str: "1394670629257887745",
                      indices: [284, 307],
                      media_url:
                        "http://pbs.twimg.com/media/E1rdjEuWQAEVnyw.png",
                      media_url_https:
                        "https://pbs.twimg.com/media/E1rdjEuWQAEVnyw.png",
                      url: "https://t.co/SHmFdy6pHz",
                      display_url: "pic.twitter.com/SHmFdy6pHz",
                      expanded_url:
                        "https://twitter.com/fedscoop/status/1394670632118403075/photo/1",
                      type: "photo",
                      sizes: {
                        small: {
                          w: 680,
                          h: 383,
                          resize: "fit",
                        },
                        medium: {
                          w: 1200,
                          h: 675,
                          resize: "fit",
                        },
                        large: {
                          w: 1200,
                          h: 675,
                          resize: "fit",
                        },
                        thumb: {
                          w: 150,
                          h: 150,
                          resize: "crop",
                        },
                      },
                    },
                  ],
                },
                extended_entities: {
                  media: [
                    {
                      id: 1394670629257887745,
                      id_str: "1394670629257887745",
                      indices: [284, 307],
                      media_url:
                        "http://pbs.twimg.com/media/E1rdjEuWQAEVnyw.png",
                      media_url_https:
                        "https://pbs.twimg.com/media/E1rdjEuWQAEVnyw.png",
                      url: "https://t.co/SHmFdy6pHz",
                      display_url: "pic.twitter.com/SHmFdy6pHz",
                      expanded_url:
                        "https://twitter.com/fedscoop/status/1394670632118403075/photo/1",
                      type: "photo",
                      sizes: {
                        small: {
                          w: 680,
                          h: 383,
                          resize: "fit",
                        },
                        medium: {
                          w: 1200,
                          h: 675,
                          resize: "fit",
                        },
                        large: {
                          w: 1200,
                          h: 675,
                          resize: "fit",
                        },
                        thumb: {
                          w: 150,
                          h: 150,
                          resize: "crop",
                        },
                      },
                    },
                  ],
                },
                metadata: {
                  iso_language_code: "en",
                  result_type: "recent",
                },
                source:
                  '<a href="http://www.hubspot.com/" rel="nofollow">HubSpot</a>',
                in_reply_to_status_id: null,
                in_reply_to_status_id_str: null,
                in_reply_to_user_id: null,
                in_reply_to_user_id_str: null,
                in_reply_to_screen_name: null,
                user: {
                  id: 1477949676,
                  id_str: "1477949676",
                  name: "FedScoop",
                  screen_name: "fedscoop",
                  location: "Washington, D.C.",
                  description:
                    "FedScoop, a @ScoopNewsGroup property, gathers leaders from federal agencies, academia and the tech industry to discuss ways technology can improve government",
                  url: "https://t.co/hwDbuHa7Px",
                  entities: {
                    url: {
                      urls: [
                        {
                          url: "https://t.co/hwDbuHa7Px",
                          expanded_url: "http://www.fedscoop.com",
                          display_url: "fedscoop.com",
                          indices: [0, 23],
                        },
                      ],
                    },
                    description: {
                      urls: [],
                    },
                  },
                  protected: false,
                  followers_count: 13857,
                  friends_count: 359,
                  listed_count: 563,
                  created_at: "Sun Jun 02 18:58:39 +0000 2013",
                  favourites_count: 5361,
                  utc_offset: null,
                  time_zone: null,
                  geo_enabled: true,
                  verified: false,
                  statuses_count: 29929,
                  lang: null,
                  contributors_enabled: false,
                  is_translator: false,
                  is_translation_enabled: false,
                  profile_background_color: "000000",
                  profile_background_image_url:
                    "http://abs.twimg.com/images/themes/theme1/bg.png",
                  profile_background_image_url_https:
                    "https://abs.twimg.com/images/themes/theme1/bg.png",
                  profile_background_tile: false,
                  profile_image_url:
                    "http://pbs.twimg.com/profile_images/1354826965044363271/bnl5hXcb_normal.jpg",
                  profile_image_url_https:
                    "https://pbs.twimg.com/profile_images/1354826965044363271/bnl5hXcb_normal.jpg",
                  profile_banner_url:
                    "https://pbs.twimg.com/profile_banners/1477949676/1552512995",
                  profile_link_color: "1B95E0",
                  profile_sidebar_border_color: "000000",
                  profile_sidebar_fill_color: "000000",
                  profile_text_color: "000000",
                  profile_use_background_image: false,
                  has_extended_profile: false,
                  default_profile: false,
                  default_profile_image: false,
                  following: null,
                  follow_request_sent: null,
                  notifications: null,
                  translator_type: "none",
                  withheld_in_countries: [],
                },
                geo: null,
                coordinates: null,
                place: null,
                contributors: null,
                is_quote_status: false,
                retweet_count: 1,
                favorite_count: 1,
                favorited: false,
                retweeted: false,
                possibly_sensitive: false,
                lang: "en",
              },
              {
                created_at: "Tue May 18 15:04:12 +0000 2021",
                id: 1394670161349812226,
                id_str: "1394670161349812226",
                full_text:
                  "DOD takes automation a step further with machine learning - FedScoop\n\nRead more here: https://t.co/z0rUx17I1r\n\n#ArtificialIntelligence #AI #DataScience #MachineLearning #BigData #DeepLearning #NLP #Robots #IoT",
                truncated: false,
                display_text_range: [0, 209],
                entities: {
                  hashtags: [
                    {
                      text: "ArtificialIntelligence",
                      indices: [111, 134],
                    },
                    {
                      text: "AI",
                      indices: [135, 138],
                    },
                    {
                      text: "DataScience",
                      indices: [139, 151],
                    },
                    {
                      text: "MachineLearning",
                      indices: [152, 168],
                    },
                    {
                      text: "BigData",
                      indices: [169, 177],
                    },
                    {
                      text: "DeepLearning",
                      indices: [178, 191],
                    },
                    {
                      text: "NLP",
                      indices: [192, 196],
                    },
                    {
                      text: "Robots",
                      indices: [197, 204],
                    },
                    {
                      text: "IoT",
                      indices: [205, 209],
                    },
                  ],
                  symbols: [],
                  user_mentions: [],
                  urls: [
                    {
                      url: "https://t.co/z0rUx17I1r",
                      expanded_url: "https://ift.tt/33zuBvt",
                      display_url: "ift.tt/33zuBvt",
                      indices: [86, 109],
                    },
                  ],
                },
                metadata: {
                  iso_language_code: "en",
                  result_type: "recent",
                },
                source: '<a href="https://ifttt.com" rel="nofollow">IFTTT</a>',
                in_reply_to_status_id: null,
                in_reply_to_status_id_str: null,
                in_reply_to_user_id: null,
                in_reply_to_user_id_str: null,
                in_reply_to_screen_name: null,
                user: {
                  id: 932543605700427776,
                  id_str: "932543605700427776",
                  name: "Fabien Brodie",
                  screen_name: "FabienBrodie",
                  location: "London, England",
                  description:
                    "#Explorer and #Adventurer. Big fan of #Tech and working with #BigData",
                  url: null,
                  entities: {
                    description: {
                      urls: [],
                    },
                  },
                  protected: false,
                  followers_count: 630,
                  friends_count: 258,
                  listed_count: 27,
                  created_at: "Mon Nov 20 09:38:16 +0000 2017",
                  favourites_count: 26590,
                  utc_offset: null,
                  time_zone: null,
                  geo_enabled: false,
                  verified: false,
                  statuses_count: 77623,
                  lang: null,
                  contributors_enabled: false,
                  is_translator: false,
                  is_translation_enabled: false,
                  profile_background_color: "F5F8FA",
                  profile_background_image_url: null,
                  profile_background_image_url_https: null,
                  profile_background_tile: false,
                  profile_image_url:
                    "http://pbs.twimg.com/profile_images/1357638618563624962/8ZHXxJMh_normal.jpg",
                  profile_image_url_https:
                    "https://pbs.twimg.com/profile_images/1357638618563624962/8ZHXxJMh_normal.jpg",
                  profile_banner_url:
                    "https://pbs.twimg.com/profile_banners/932543605700427776/1511171224",
                  profile_link_color: "1DA1F2",
                  profile_sidebar_border_color: "C0DEED",
                  profile_sidebar_fill_color: "DDEEF6",
                  profile_text_color: "333333",
                  profile_use_background_image: true,
                  has_extended_profile: true,
                  default_profile: true,
                  default_profile_image: false,
                  following: null,
                  follow_request_sent: null,
                  notifications: null,
                  translator_type: "none",
                  withheld_in_countries: [],
                },
                geo: null,
                coordinates: null,
                place: null,
                contributors: null,
                is_quote_status: false,
                retweet_count: 0,
                favorite_count: 0,
                favorited: false,
                retweeted: false,
                possibly_sensitive: false,
                lang: "en",
              },
              {
                created_at: "Tue May 18 15:01:19 +0000 2021",
                id: 1394669435479285761,
                id_str: "1394669435479285761",
                full_text:
                  "Join us May 26th at #LEAPS2021 to hear #technology leaders from @DHSgov, @USCG, @CBP &amp; @TheJusticeDept share current and future #modernization strategies. Moderated by Wyatt Kash, @fedscoop. Thank you to #CXO panel sponsor @SnowflakeDB!\n\nRegister: https://t.co/pFjhxnDGrs https://t.co/7tTQ7saynd",
                truncated: false,
                display_text_range: [0, 275],
                entities: {
                  hashtags: [
                    {
                      text: "LEAPS2021",
                      indices: [20, 30],
                    },
                    {
                      text: "technology",
                      indices: [39, 50],
                    },
                    {
                      text: "modernization",
                      indices: [132, 146],
                    },
                    {
                      text: "CXO",
                      indices: [208, 212],
                    },
                  ],
                  symbols: [],
                  user_mentions: [
                    {
                      screen_name: "DHSgov",
                      name: "Homeland Security",
                      id: 15647676,
                      id_str: "15647676",
                      indices: [64, 71],
                    },
                    {
                      screen_name: "USCG",
                      name: "U.S. Coast Guard",
                      id: 15113565,
                      id_str: "15113565",
                      indices: [73, 78],
                    },
                    {
                      screen_name: "CBP",
                      name: "CBP",
                      id: 16613128,
                      id_str: "16613128",
                      indices: [80, 84],
                    },
                    {
                      screen_name: "TheJusticeDept",
                      name: "Justice Department",
                      id: 73181712,
                      id_str: "73181712",
                      indices: [91, 106],
                    },
                    {
                      screen_name: "fedscoop",
                      name: "FedScoop",
                      id: 1477949676,
                      id_str: "1477949676",
                      indices: [184, 193],
                    },
                    {
                      screen_name: "SnowflakeDB",
                      name: "Snowflake",
                      id: 2539962439,
                      id_str: "2539962439",
                      indices: [227, 239],
                    },
                  ],
                  urls: [
                    {
                      url: "https://t.co/pFjhxnDGrs",
                      expanded_url: "http://ow.ly/F02D50EMhkA",
                      display_url: "ow.ly/F02D50EMhkA",
                      indices: [252, 275],
                    },
                  ],
                  media: [
                    {
                      id: 1394669434187485187,
                      id_str: "1394669434187485187",
                      indices: [276, 299],
                      media_url:
                        "http://pbs.twimg.com/media/E1rcdgvXEAMifl8.jpg",
                      media_url_https:
                        "https://pbs.twimg.com/media/E1rcdgvXEAMifl8.jpg",
                      url: "https://t.co/7tTQ7saynd",
                      display_url: "pic.twitter.com/7tTQ7saynd",
                      expanded_url:
                        "https://twitter.com/AFCEABethesda/status/1394669435479285761/photo/1",
                      type: "photo",
                      sizes: {
                        thumb: {
                          w: 150,
                          h: 150,
                          resize: "crop",
                        },
                        small: {
                          w: 680,
                          h: 340,
                          resize: "fit",
                        },
                        medium: {
                          w: 1024,
                          h: 512,
                          resize: "fit",
                        },
                        large: {
                          w: 1024,
                          h: 512,
                          resize: "fit",
                        },
                      },
                    },
                  ],
                },
                extended_entities: {
                  media: [
                    {
                      id: 1394669434187485187,
                      id_str: "1394669434187485187",
                      indices: [276, 299],
                      media_url:
                        "http://pbs.twimg.com/media/E1rcdgvXEAMifl8.jpg",
                      media_url_https:
                        "https://pbs.twimg.com/media/E1rcdgvXEAMifl8.jpg",
                      url: "https://t.co/7tTQ7saynd",
                      display_url: "pic.twitter.com/7tTQ7saynd",
                      expanded_url:
                        "https://twitter.com/AFCEABethesda/status/1394669435479285761/photo/1",
                      type: "photo",
                      sizes: {
                        thumb: {
                          w: 150,
                          h: 150,
                          resize: "crop",
                        },
                        small: {
                          w: 680,
                          h: 340,
                          resize: "fit",
                        },
                        medium: {
                          w: 1024,
                          h: 512,
                          resize: "fit",
                        },
                        large: {
                          w: 1024,
                          h: 512,
                          resize: "fit",
                        },
                      },
                    },
                  ],
                },
                metadata: {
                  iso_language_code: "en",
                  result_type: "recent",
                },
                source:
                  '<a href="https://www.hootsuite.com" rel="nofollow">Hootsuite Inc.</a>',
                in_reply_to_status_id: null,
                in_reply_to_status_id_str: null,
                in_reply_to_user_id: null,
                in_reply_to_user_id_str: null,
                in_reply_to_screen_name: null,
                user: {
                  id: 71566459,
                  id_str: "71566459",
                  name: "AFCEA Bethesda",
                  screen_name: "AFCEABethesda",
                  location: "Bethesda, MD",
                  description:
                    "An educationally based, non-profit organization which encourages greater government/industry partnerships and information exchange.",
                  url: "http://t.co/zx4zNKRWgA",
                  entities: {
                    url: {
                      urls: [
                        {
                          url: "http://t.co/zx4zNKRWgA",
                          expanded_url: "http://www.afceabethesda.com",
                          display_url: "afceabethesda.com",
                          indices: [0, 22],
                        },
                      ],
                    },
                    description: {
                      urls: [],
                    },
                  },
                  protected: false,
                  followers_count: 3028,
                  friends_count: 661,
                  listed_count: 115,
                  created_at: "Fri Sep 04 15:51:54 +0000 2009",
                  favourites_count: 1378,
                  utc_offset: null,
                  time_zone: null,
                  geo_enabled: true,
                  verified: false,
                  statuses_count: 6240,
                  lang: null,
                  contributors_enabled: false,
                  is_translator: false,
                  is_translation_enabled: false,
                  profile_background_color: "9AE4E8",
                  profile_background_image_url:
                    "http://abs.twimg.com/images/themes/theme1/bg.png",
                  profile_background_image_url_https:
                    "https://abs.twimg.com/images/themes/theme1/bg.png",
                  profile_background_tile: true,
                  profile_image_url:
                    "http://pbs.twimg.com/profile_images/1220026931233611779/3S4D4K0m_normal.jpg",
                  profile_image_url_https:
                    "https://pbs.twimg.com/profile_images/1220026931233611779/3S4D4K0m_normal.jpg",
                  profile_banner_url:
                    "https://pbs.twimg.com/profile_banners/71566459/1579712099",
                  profile_link_color: "0084B4",
                  profile_sidebar_border_color: "FFFFFF",
                  profile_sidebar_fill_color: "DDFFCC",
                  profile_text_color: "333333",
                  profile_use_background_image: true,
                  has_extended_profile: false,
                  default_profile: false,
                  default_profile_image: false,
                  following: null,
                  follow_request_sent: null,
                  notifications: null,
                  translator_type: "none",
                  withheld_in_countries: [],
                },
                geo: null,
                coordinates: null,
                place: null,
                contributors: null,
                is_quote_status: false,
                retweet_count: 0,
                favorite_count: 1,
                favorited: false,
                retweeted: false,
                possibly_sensitive: false,
                lang: "en",
              },
              {
                created_at: "Tue May 18 14:42:03 +0000 2021",
                id: 1394664584368332803,
                id_str: "1394664584368332803",
                full_text:
                  "RT @fedscoop: Next, a panel on Leveraging Data for Emerging Technologies w/ Marcus Comiter, Chief, Data Science &amp; Artificial Intelligence E…",
                truncated: false,
                display_text_range: [0, 144],
                entities: {
                  hashtags: [],
                  symbols: [],
                  user_mentions: [
                    {
                      screen_name: "fedscoop",
                      name: "FedScoop",
                      id: 1477949676,
                      id_str: "1477949676",
                      indices: [3, 12],
                    },
                  ],
                  urls: [],
                },
                metadata: {
                  iso_language_code: "en",
                  result_type: "recent",
                },
                source:
                  '<a href="https://help.twitter.com/en/using-twitter/how-to-tweet#source-labels" rel="nofollow">ZedTweetBot</a>',
                in_reply_to_status_id: null,
                in_reply_to_status_id_str: null,
                in_reply_to_user_id: null,
                in_reply_to_user_id_str: null,
                in_reply_to_screen_name: null,
                user: {
                  id: 1318814261666172929,
                  id_str: "1318814261666172929",
                  name: "Discriminant Bot",
                  screen_name: "DiscriminantBot",
                  location: "Created by: Zedeca",
                  description:
                    "•Bot which retweets the tweets having 'Artificial Intelligence' in their contents• \n•Welcome to my large repository of AI News•\n#Top_10_Tech_Influencer",
                  url: null,
                  entities: {
                    description: {
                      urls: [],
                    },
                  },
                  protected: false,
                  followers_count: 654,
                  friends_count: 586,
                  listed_count: 21,
                  created_at: "Wed Oct 21 07:20:28 +0000 2020",
                  favourites_count: 1304,
                  utc_offset: null,
                  time_zone: null,
                  geo_enabled: false,
                  verified: false,
                  statuses_count: 150002,
                  lang: null,
                  contributors_enabled: false,
                  is_translator: false,
                  is_translation_enabled: false,
                  profile_background_color: "F5F8FA",
                  profile_background_image_url: null,
                  profile_background_image_url_https: null,
                  profile_background_tile: false,
                  profile_image_url:
                    "http://pbs.twimg.com/profile_images/1328107099025338368/ca37zFbV_normal.jpg",
                  profile_image_url_https:
                    "https://pbs.twimg.com/profile_images/1328107099025338368/ca37zFbV_normal.jpg",
                  profile_banner_url:
                    "https://pbs.twimg.com/profile_banners/1318814261666172929/1605635973",
                  profile_link_color: "1DA1F2",
                  profile_sidebar_border_color: "C0DEED",
                  profile_sidebar_fill_color: "DDEEF6",
                  profile_text_color: "333333",
                  profile_use_background_image: true,
                  has_extended_profile: false,
                  default_profile: true,
                  default_profile_image: false,
                  following: null,
                  follow_request_sent: null,
                  notifications: null,
                  translator_type: "none",
                  withheld_in_countries: [],
                },
                geo: null,
                coordinates: null,
                place: null,
                contributors: null,
                retweeted_status: {
                  created_at: "Tue May 18 14:40:07 +0000 2021",
                  id: 1394664097938132992,
                  id_str: "1394664097938132992",
                  full_text:
                    "Next, a panel on Leveraging Data for Emerging Technologies w/ Marcus Comiter, Chief, Data Science &amp; Artificial Intelligence Engineering Division, @DoDJAIC, and @keithMcat, Managing Director, @Deloitte. #DatainAction https://t.co/qQwh9EZXGN",
                  truncated: false,
                  display_text_range: [0, 219],
                  entities: {
                    hashtags: [
                      {
                        text: "DatainAction",
                        indices: [206, 219],
                      },
                    ],
                    symbols: [],
                    user_mentions: [
                      {
                        screen_name: "DoDJAIC",
                        name: "Joint Artificial Intelligence Center",
                        id: 1151651805316734976,
                        id_str: "1151651805316734976",
                        indices: [150, 158],
                      },
                      {
                        screen_name: "keithMcat",
                        name: "Keith Catanzano",
                        id: 237982849,
                        id_str: "237982849",
                        indices: [164, 174],
                      },
                      {
                        screen_name: "Deloitte",
                        name: "Deloitte",
                        id: 8457092,
                        id_str: "8457092",
                        indices: [195, 204],
                      },
                    ],
                    urls: [],
                    media: [
                      {
                        id: 1394664095899656197,
                        id_str: "1394664095899656197",
                        indices: [220, 243],
                        media_url:
                          "http://pbs.twimg.com/media/E1rXmyEXIAUMdo7.png",
                        media_url_https:
                          "https://pbs.twimg.com/media/E1rXmyEXIAUMdo7.png",
                        url: "https://t.co/qQwh9EZXGN",
                        display_url: "pic.twitter.com/qQwh9EZXGN",
                        expanded_url:
                          "https://twitter.com/fedscoop/status/1394664097938132992/photo/1",
                        type: "photo",
                        sizes: {
                          large: {
                            w: 1200,
                            h: 675,
                            resize: "fit",
                          },
                          small: {
                            w: 680,
                            h: 383,
                            resize: "fit",
                          },
                          thumb: {
                            w: 150,
                            h: 150,
                            resize: "crop",
                          },
                          medium: {
                            w: 1200,
                            h: 675,
                            resize: "fit",
                          },
                        },
                      },
                    ],
                  },
                  extended_entities: {
                    media: [
                      {
                        id: 1394664095899656197,
                        id_str: "1394664095899656197",
                        indices: [220, 243],
                        media_url:
                          "http://pbs.twimg.com/media/E1rXmyEXIAUMdo7.png",
                        media_url_https:
                          "https://pbs.twimg.com/media/E1rXmyEXIAUMdo7.png",
                        url: "https://t.co/qQwh9EZXGN",
                        display_url: "pic.twitter.com/qQwh9EZXGN",
                        expanded_url:
                          "https://twitter.com/fedscoop/status/1394664097938132992/photo/1",
                        type: "photo",
                        sizes: {
                          large: {
                            w: 1200,
                            h: 675,
                            resize: "fit",
                          },
                          small: {
                            w: 680,
                            h: 383,
                            resize: "fit",
                          },
                          thumb: {
                            w: 150,
                            h: 150,
                            resize: "crop",
                          },
                          medium: {
                            w: 1200,
                            h: 675,
                            resize: "fit",
                          },
                        },
                      },
                    ],
                  },
                  metadata: {
                    iso_language_code: "en",
                    result_type: "recent",
                  },
                  source:
                    '<a href="http://www.hubspot.com/" rel="nofollow">HubSpot</a>',
                  in_reply_to_status_id: null,
                  in_reply_to_status_id_str: null,
                  in_reply_to_user_id: null,
                  in_reply_to_user_id_str: null,
                  in_reply_to_screen_name: null,
                  user: {
                    id: 1477949676,
                    id_str: "1477949676",
                    name: "FedScoop",
                    screen_name: "fedscoop",
                    location: "Washington, D.C.",
                    description:
                      "FedScoop, a @ScoopNewsGroup property, gathers leaders from federal agencies, academia and the tech industry to discuss ways technology can improve government",
                    url: "https://t.co/hwDbuHa7Px",
                    entities: {
                      url: {
                        urls: [
                          {
                            url: "https://t.co/hwDbuHa7Px",
                            expanded_url: "http://www.fedscoop.com",
                            display_url: "fedscoop.com",
                            indices: [0, 23],
                          },
                        ],
                      },
                      description: {
                        urls: [],
                      },
                    },
                    protected: false,
                    followers_count: 13857,
                    friends_count: 359,
                    listed_count: 563,
                    created_at: "Sun Jun 02 18:58:39 +0000 2013",
                    favourites_count: 5361,
                    utc_offset: null,
                    time_zone: null,
                    geo_enabled: true,
                    verified: false,
                    statuses_count: 29929,
                    lang: null,
                    contributors_enabled: false,
                    is_translator: false,
                    is_translation_enabled: false,
                    profile_background_color: "000000",
                    profile_background_image_url:
                      "http://abs.twimg.com/images/themes/theme1/bg.png",
                    profile_background_image_url_https:
                      "https://abs.twimg.com/images/themes/theme1/bg.png",
                    profile_background_tile: false,
                    profile_image_url:
                      "http://pbs.twimg.com/profile_images/1354826965044363271/bnl5hXcb_normal.jpg",
                    profile_image_url_https:
                      "https://pbs.twimg.com/profile_images/1354826965044363271/bnl5hXcb_normal.jpg",
                    profile_banner_url:
                      "https://pbs.twimg.com/profile_banners/1477949676/1552512995",
                    profile_link_color: "1B95E0",
                    profile_sidebar_border_color: "000000",
                    profile_sidebar_fill_color: "000000",
                    profile_text_color: "000000",
                    profile_use_background_image: false,
                    has_extended_profile: false,
                    default_profile: false,
                    default_profile_image: false,
                    following: null,
                    follow_request_sent: null,
                    notifications: null,
                    translator_type: "none",
                    withheld_in_countries: [],
                  },
                  geo: null,
                  coordinates: null,
                  place: null,
                  contributors: null,
                  is_quote_status: false,
                  retweet_count: 1,
                  favorite_count: 0,
                  favorited: false,
                  retweeted: false,
                  possibly_sensitive: false,
                  lang: "en",
                },
                is_quote_status: false,
                retweet_count: 1,
                favorite_count: 0,
                favorited: false,
                retweeted: false,
                lang: "en",
              },
              {
                created_at: "Tue May 18 14:40:07 +0000 2021",
                id: 1394664097938132992,
                id_str: "1394664097938132992",
                full_text:
                  "Next, a panel on Leveraging Data for Emerging Technologies w/ Marcus Comiter, Chief, Data Science &amp; Artificial Intelligence Engineering Division, @DoDJAIC, and @keithMcat, Managing Director, @Deloitte. #DatainAction https://t.co/qQwh9EZXGN",
                truncated: false,
                display_text_range: [0, 219],
                entities: {
                  hashtags: [
                    {
                      text: "DatainAction",
                      indices: [206, 219],
                    },
                  ],
                  symbols: [],
                  user_mentions: [
                    {
                      screen_name: "DoDJAIC",
                      name: "Joint Artificial Intelligence Center",
                      id: 1151651805316734976,
                      id_str: "1151651805316734976",
                      indices: [150, 158],
                    },
                    {
                      screen_name: "keithMcat",
                      name: "Keith Catanzano",
                      id: 237982849,
                      id_str: "237982849",
                      indices: [164, 174],
                    },
                    {
                      screen_name: "Deloitte",
                      name: "Deloitte",
                      id: 8457092,
                      id_str: "8457092",
                      indices: [195, 204],
                    },
                  ],
                  urls: [],
                  media: [
                    {
                      id: 1394664095899656197,
                      id_str: "1394664095899656197",
                      indices: [220, 243],
                      media_url:
                        "http://pbs.twimg.com/media/E1rXmyEXIAUMdo7.png",
                      media_url_https:
                        "https://pbs.twimg.com/media/E1rXmyEXIAUMdo7.png",
                      url: "https://t.co/qQwh9EZXGN",
                      display_url: "pic.twitter.com/qQwh9EZXGN",
                      expanded_url:
                        "https://twitter.com/fedscoop/status/1394664097938132992/photo/1",
                      type: "photo",
                      sizes: {
                        large: {
                          w: 1200,
                          h: 675,
                          resize: "fit",
                        },
                        small: {
                          w: 680,
                          h: 383,
                          resize: "fit",
                        },
                        thumb: {
                          w: 150,
                          h: 150,
                          resize: "crop",
                        },
                        medium: {
                          w: 1200,
                          h: 675,
                          resize: "fit",
                        },
                      },
                    },
                  ],
                },
                extended_entities: {
                  media: [
                    {
                      id: 1394664095899656197,
                      id_str: "1394664095899656197",
                      indices: [220, 243],
                      media_url:
                        "http://pbs.twimg.com/media/E1rXmyEXIAUMdo7.png",
                      media_url_https:
                        "https://pbs.twimg.com/media/E1rXmyEXIAUMdo7.png",
                      url: "https://t.co/qQwh9EZXGN",
                      display_url: "pic.twitter.com/qQwh9EZXGN",
                      expanded_url:
                        "https://twitter.com/fedscoop/status/1394664097938132992/photo/1",
                      type: "photo",
                      sizes: {
                        large: {
                          w: 1200,
                          h: 675,
                          resize: "fit",
                        },
                        small: {
                          w: 680,
                          h: 383,
                          resize: "fit",
                        },
                        thumb: {
                          w: 150,
                          h: 150,
                          resize: "crop",
                        },
                        medium: {
                          w: 1200,
                          h: 675,
                          resize: "fit",
                        },
                      },
                    },
                  ],
                },
                metadata: {
                  iso_language_code: "en",
                  result_type: "recent",
                },
                source:
                  '<a href="http://www.hubspot.com/" rel="nofollow">HubSpot</a>',
                in_reply_to_status_id: null,
                in_reply_to_status_id_str: null,
                in_reply_to_user_id: null,
                in_reply_to_user_id_str: null,
                in_reply_to_screen_name: null,
                user: {
                  id: 1477949676,
                  id_str: "1477949676",
                  name: "FedScoop",
                  screen_name: "fedscoop",
                  location: "Washington, D.C.",
                  description:
                    "FedScoop, a @ScoopNewsGroup property, gathers leaders from federal agencies, academia and the tech industry to discuss ways technology can improve government",
                  url: "https://t.co/hwDbuHa7Px",
                  entities: {
                    url: {
                      urls: [
                        {
                          url: "https://t.co/hwDbuHa7Px",
                          expanded_url: "http://www.fedscoop.com",
                          display_url: "fedscoop.com",
                          indices: [0, 23],
                        },
                      ],
                    },
                    description: {
                      urls: [],
                    },
                  },
                  protected: false,
                  followers_count: 13857,
                  friends_count: 359,
                  listed_count: 563,
                  created_at: "Sun Jun 02 18:58:39 +0000 2013",
                  favourites_count: 5361,
                  utc_offset: null,
                  time_zone: null,
                  geo_enabled: true,
                  verified: false,
                  statuses_count: 29929,
                  lang: null,
                  contributors_enabled: false,
                  is_translator: false,
                  is_translation_enabled: false,
                  profile_background_color: "000000",
                  profile_background_image_url:
                    "http://abs.twimg.com/images/themes/theme1/bg.png",
                  profile_background_image_url_https:
                    "https://abs.twimg.com/images/themes/theme1/bg.png",
                  profile_background_tile: false,
                  profile_image_url:
                    "http://pbs.twimg.com/profile_images/1354826965044363271/bnl5hXcb_normal.jpg",
                  profile_image_url_https:
                    "https://pbs.twimg.com/profile_images/1354826965044363271/bnl5hXcb_normal.jpg",
                  profile_banner_url:
                    "https://pbs.twimg.com/profile_banners/1477949676/1552512995",
                  profile_link_color: "1B95E0",
                  profile_sidebar_border_color: "000000",
                  profile_sidebar_fill_color: "000000",
                  profile_text_color: "000000",
                  profile_use_background_image: false,
                  has_extended_profile: false,
                  default_profile: false,
                  default_profile_image: false,
                  following: null,
                  follow_request_sent: null,
                  notifications: null,
                  translator_type: "none",
                  withheld_in_countries: [],
                },
                geo: null,
                coordinates: null,
                place: null,
                contributors: null,
                is_quote_status: false,
                retweet_count: 1,
                favorite_count: 0,
                favorited: false,
                retweeted: false,
                possibly_sensitive: false,
                lang: "en",
              },
              {
                created_at: "Tue May 18 14:26:44 +0000 2021",
                id: 1394660732533239810,
                id_str: "1394660732533239810",
                full_text:
                  "Who is making these voting machines? https://t.co/kSWnIyKKkD",
                truncated: false,
                display_text_range: [0, 60],
                entities: {
                  hashtags: [],
                  symbols: [],
                  user_mentions: [],
                  urls: [
                    {
                      url: "https://t.co/kSWnIyKKkD",
                      expanded_url:
                        "https://www.fedscoop.com/house-got-brand-new-electronic-voting-system-summer/",
                      display_url: "fedscoop.com/house-got-bran…",
                      indices: [37, 60],
                    },
                  ],
                },
                metadata: {
                  iso_language_code: "en",
                  result_type: "recent",
                },
                source:
                  '<a href="http://twitter.com/download/iphone" rel="nofollow">Twitter for iPhone</a>',
                in_reply_to_status_id: null,
                in_reply_to_status_id_str: null,
                in_reply_to_user_id: null,
                in_reply_to_user_id_str: null,
                in_reply_to_screen_name: null,
                user: {
                  id: 46979792,
                  id_str: "46979792",
                  name: "NormalGuy",
                  screen_name: "GodFamUSA",
                  location: "Arizona, USA",
                  description: "",
                  url: null,
                  entities: {
                    description: {
                      urls: [],
                    },
                  },
                  protected: false,
                  followers_count: 53,
                  friends_count: 651,
                  listed_count: 1,
                  created_at: "Sat Jun 13 22:44:17 +0000 2009",
                  favourites_count: 4694,
                  utc_offset: null,
                  time_zone: null,
                  geo_enabled: false,
                  verified: false,
                  statuses_count: 1733,
                  lang: null,
                  contributors_enabled: false,
                  is_translator: false,
                  is_translation_enabled: false,
                  profile_background_color: "C0DEED",
                  profile_background_image_url:
                    "http://abs.twimg.com/images/themes/theme1/bg.png",
                  profile_background_image_url_https:
                    "https://abs.twimg.com/images/themes/theme1/bg.png",
                  profile_background_tile: false,
                  profile_image_url:
                    "http://pbs.twimg.com/profile_images/1366460924165455872/XbEqBBYW_normal.jpg",
                  profile_image_url_https:
                    "https://pbs.twimg.com/profile_images/1366460924165455872/XbEqBBYW_normal.jpg",
                  profile_link_color: "1DA1F2",
                  profile_sidebar_border_color: "C0DEED",
                  profile_sidebar_fill_color: "DDEEF6",
                  profile_text_color: "333333",
                  profile_use_background_image: true,
                  has_extended_profile: false,
                  default_profile: true,
                  default_profile_image: false,
                  following: null,
                  follow_request_sent: null,
                  notifications: null,
                  translator_type: "none",
                  withheld_in_countries: [],
                },
                geo: null,
                coordinates: null,
                place: null,
                contributors: null,
                is_quote_status: false,
                retweet_count: 0,
                favorite_count: 0,
                favorited: false,
                retweeted: false,
                possibly_sensitive: false,
                lang: "en",
              },
            ],
            search_metadata: {
              completed_in: 0.077,
              max_id: 1394682449813282817,
              max_id_str: "1394682449813282817",
              next_results:
                "?max_id=1394660732533239809&q=fedscoop&include_entities=1",
              query: "fedscoop",
              refresh_url:
                "?since_id=1394682449813282817&q=fedscoop&include_entities=1",
              count: 15,
              since_id: 0,
              since_id_str: "0",
            },
          })
        );
      }, 300);
    }
  );
};

export const incrementInteractionItem = async (interactionItemId: string) => {
  const client = await generateClient();
  return client.graphql({
    query: `mutation incrementInteractionItem($eventId: String!, $interactionItemId: String!) {
      incrementInteractionItem(eventId: $eventId, interactionItemId: $interactionItemId) {
        interactionItemId
        count
        updated
      }
    }`,
    variables: {
      eventId: eventId,
      interactionItemId,
    },
  });
};

export const queryInteractionItem = async (interactionItemId: string) => {
  const client = await generateClient();
  return client.graphql({
    query: `query queryInteractionItem($eventId: String!, $interactionItemId: String!) {
      getInteractionItem(eventId: $eventId, interactionItemId: $interactionItemId) {
        count
        created
        description
        eventId
        interactionId
        interactionItemId
        label
        updated
      }
    }`,
    variables: {
      eventId: eventId,
      interactionItemId,
    },
    authMode: GRAPHQL_AUTH_MODE.API_KEY,
  });
};
