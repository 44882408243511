import React from 'react'
import { AuthModalProvider } from "../../context/AuthModalContext"
import { GatedLinkModalProvider } from "../../context/GatedLinkModalProvider";

export const WrapRootElement = ({ element }) => (
  <AuthModalProvider>
    <GatedLinkModalProvider>
      {element}
    </GatedLinkModalProvider>
  </AuthModalProvider>
)

export default WrapRootElement
