import {
  signIn as cognitoSignIn,
  signOut as cognitoSignOut,
  signUp as cognitoSignUp,
  getCurrentUser,
  type SignInInput
} from "aws-amplify/auth";
import { navigate, withPrefix } from "gatsby";
import useSWR, { useSWRConfig } from "swr";
import { v4 as uuidv4 } from "uuid";
import { trackMetric } from "../../services";
import { handleSignUpErrors } from "../../services/auth";
import useSessionStore from "../../store/useSessionsStore";
import { SignOutOptions, SignUpProps, UseAuthOptions } from "../../types";

const eventId = process.env.GATSBY_EVENT_ID || "";

export const generatePassword = () =>
  uuidv4().replace(/\d{1}/, "G").replace(/\d{1}/, "v");

export const useUser = (options?: UseAuthOptions) => {
  const { cache } = useSWRConfig();
  const { data: user, error } = useSWR("user", getCurrentUser);
  const { selectedSessions } = useSessionStore();
  const loading = !user && !error;
  const loggedOut = error && error === "The user is not authenticated";

  if (loggedOut && options?.redirect) {
    navigate(withPrefix(options.redirect), { replace: true });
  }

  const signOut = async (options?: SignOutOptions) => {
    cache.delete("user");
    await cognitoSignOut();
    if (options?.redirect) {
      navigate(options.redirect);
    }
  };

  const logInWithEmail = async (email: string) => {
    const formatEmail = email.toLowerCase();
    const params: SignInInput = {
      username: formatEmail,
      password: "",
      options: {
        clientMetadata: {
          eventId,
        },
        authFlowType: "CUSTOM_WITHOUT_SRP",
      },
    };
    try {
      const user = await cognitoSignIn(params);
      // const userGroups =
      //   user.signInUserSession?.accessToken?.payload["cognito:groups"];

      // if (!userGroups?.includes(eventId)) {
      //   signOut();
      //   throw new Error(
      //     `Sorry, you do not have access to this event, please register!`
      //   );
      // }

      await trackMetric({
        metricType: "login",
        payload: {
          href: window?.location?.href,
          email: formatEmail,
          eventId,
        },
      });
    } catch (error) {
      handleSignUpErrors(error);
    }
  };

  const signUp = async (data: SignUpProps) => {
    const email = data.email.toLowerCase();
    const params = {
      username: email,
      password: generatePassword(),
    };
    const payload = {
      href: window?.location?.href,
      eventId,
      agenda: selectedSessions,
      ...data,
      ...params,
    };

    try {
      await cognitoSignUp({
        ...params,
        options: {
          userAttributes: {
            email,
          },
          clientMetadata: {
            eventId,
            groupName: eventId,
          },
        },
      });
      await trackMetric({
        metricType: "registration",
        payload,
      });
    } catch (error: any) {
      const errorType = error?.code || error?.name;
      if (errorType !== "UsernameExistsException") {
        handleSignUpErrors(error);
      } else {
        await trackMetric({
          metricType: "registration",
          payload,
        });
      }
    }
    if (data?.autoLogin) {
      await logInWithEmail(email);
    } else {
      console.warn(
        `User exists but autoLogin is false. Set to true to log user in`
      );
    }
  };

  return {
    user,
    loggedOut,
    loading,
    logInWithEmail,
    signOut,
    signUp,
  };
};

export default useUser;
