import QueryString from "qs";
import create from "zustand";
import { persist } from "zustand/middleware";
import { UtmCode, UtmCodeStore } from "./types";

export const queryStringParseOptions = {
  ignoreQueryPrefix: true,
  parameterLimit: 20,
};

/**
 * @see https://github.com/pmndrs/zustand/blob/main/docs/persisting-store-data.md
 * */
export const utmCodeStorePersitOptions = {
  name: `UG__UTM_CODES__${process.env.GATSBY_EVENT_ID}`,
};

/**
 * This hook stores query paramaters as utm code from `onRouteUpdate` in `gatsby-browser.tsx`.
 * It comes with `addUtmCodes` and `clearUtmCodes` helper functions to interaction with the [Zustand](https://github.com/pmndrs/zustand/blob/main/docs/persisting-store-data.md) store.
 *
 * Since this is a Zustand store, you can use state methods and values outside of react.
 */
const useUtmCodeStore = create<UtmCodeStore>()(
  persist(
    (set) => ({
      utmCodes: {},
      addUtmCodes: (queryString) => {
        const newUtmCode = QueryString.parse(
          queryString,
          queryStringParseOptions
        ) as UtmCode;
        set(({ utmCodes }) => ({ utmCodes: { ...utmCodes, ...newUtmCode } }));
      },
      clearUtmCodes: () => {
        set(() => ({ utmCodes: {} }));
      },
    }),
    utmCodeStorePersitOptions
  )
);

export const addUtmCodes = (queryString: string) =>
  useUtmCodeStore.getState().addUtmCodes(queryString);
export const clearUtmCodes = () => useUtmCodeStore.getState().clearUtmCodes;
/**
 * Returns non-reactive fresh state
 * @returns utmCode
 */
export const utmCodes = () => useUtmCodeStore.getState().utmCodes;
export default useUtmCodeStore;
