import * as Sentry from "@sentry/gatsby";

const { version } = require('./package.json');
const debug = process.env.GATSBY_SENTRY_ENVIRONMENT !== "production"

!debug &&
 Sentry.init({
  debug,
  dsn: process.env.GATSBY_SENTRY_DSN,
  sampleRate: 0.5,
  release: version,
  environment: process.env.GATSBY_SENTRY_ENVIRONMENT ?? "development",
  initialScope: {
    tags: {
      eventId: process.env.GATSBY_EVENT_ID,
      organizationId: process.env.GATSBY_ORGANIZATION_ID,
      pathPrefix: process.env.GATSBY_PATH_PREFIX,
    },
  },
  beforeSend(event) {
    if (event?.user) {
      delete event.user?.email;
    }
    return event;
  },
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.dedupeIntegration(),
    Sentry.captureConsoleIntegration({
    levels: ["warm", "error"],
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/upgather\.com\//],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
