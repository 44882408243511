import { Amplify } from "aws-amplify";
import { unstable_batchedUpdates } from "react-dom";
import { addUtmCodes } from "./src/store/useUtmCodeStore";
export { default as wrapRootElement } from "./src/components/gatsby/wrapRootElement";

require("./src/styles/multiDayTab.css");
require("./src/styles/globalGiving.css");
require("./src/styles/speakersPage.css");
require("./src/styles/styles.css");

Amplify.configure({
  Auth: {
    // https://docs.amplify.aws/react/build-a-backend/auth/set-up-auth/
    Cognito: {
      userPoolId: process.env.GATSBY_USER_POOL_ID || "",
      userPoolClientId: process.env.GATSBY_USER_POOL_WEB_CLIENT_ID || "",
    }
  },
  API: {
    // https://docs.amplify.aws/react/build-a-backend/graphqlapi/connect-to-api/
    GraphQL: {
      region: 'us-east-1',
      defaultAuthMode: 'apiKey',
      endpoint: process.env.GATSBY_GRAPLQL_ENDPOINT || "",
      apiKey: process.env.GATSBY_GRAPLQL_API_KEY || "",
    }
  }
})

/**
 * Called when the user changes routes, including on the initial load of the app
 */

export const onRouteUpdate = ({ location }) => {
  unstable_batchedUpdates(() => {
    addUtmCodes(location.search);
  });
};
